//user login page
import React, { Component } from "react";
import { login, logout } from "../../components/redux/action/user";
import { connect } from "react-redux";
import { clearAlert } from "../../components/redux/action/alert";
import { Link } from "react-router-dom";
import { history } from "../../helpers/history";
import { NODE_API } from "../../config";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

class Login extends Component {
  constructor(props) {
    super(props);

    document.body.style.zoom = "80%";

    history.listen((location, action) => {});

    this.props.logout();

    this.state = {
      email: "",
      password: "",
      formErrors: { email: "", password: "" },
      emailValid: false,
      passwordValid: false,
      formValid: false,
      blankFields: true,
      open: false,
      forgetMail: "",
      showToast: true,
    };
  }

  componentDidMount() {
    document.body.style.zoom = "80%";
  }

  handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      blankFields: true,
    });
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  };

  // forget password modal
  handleForgetPswdModal = () => {
    const { open } = this.state;

    return (
      <Modal open={open} onClose={this.onCloseModal}>
        <h6 className="mr-2 pr-3">
          Forget Password? Enter email id and reset your password
        </h6>
        <form style={{ width: "100%", padding: "1%" }}>
          <label for="exampleInputEmail1">Email address</label>
          <input
            type="email"
            onChange={this.handleForgetMail}
            name="forgetMail"
            className="form-control mb-2"
            id="exampleInputEmail1"
            value={this.state.forgetMail}
            aria-describedby="emailHelp"
            placeholder="Enter email"
          />

          <button
            type="button"
            onClick={this.handleForgetPassword}
            className="btn btn-primary btn-sm"
          >
            Submit
          </button>
        </form>
      </Modal>
    );
  };

  handleForgetMail = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  };

  handleForgetPassword = async () => {
    let user = JSON.parse(sessionStorage.getItem("user"));
    let token;

    if (user && user.validator) {
      token = user.validator;
    } else {
      token = {};
    }

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      data: JSON.stringify({
        email: this.state.forgetMail,
      }),
    };

    // requestOptions
    try {
      const res = await axios(`${NODE_API}/auth/reset`, requestOptions);

      if (res.status && res.status === 208) {
        this.setState({ open: false });
        toast.error("Something went wrong. Try again later");
      } else if (res.status && res.status === 200) {
        this.setState({ open: false });
        toast.success("Check your mail id to reset a password");
      } else {
        this.setState({ open: false });
        toast.error("Something went wrong. Try again later");
      }
    } catch (e) {
      this.setState({ open: false });
      toast.error("Something went wrong. Try again later");
    }
  };

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let emailValid = this.state.emailValid;
    let passwordValid = this.state.passwordValid;

    switch (fieldName) {
      case "email":
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.email = emailValid ? "" : " is invalid";
        break;
      case "password":
        passwordValid = value.length >= 6;
        fieldValidationErrors.password = passwordValid ? "" : " is too short";
        break;
      default:
        break;
    }

    this.setState(
      {
        formErrors: fieldValidationErrors,
        emailValid: emailValid,
        passwordValid: passwordValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid: this.state.emailValid && this.state.passwordValid,
    });
  }

  errorClass(error) {
    return error.length === 0 ? "" : "has-error";
  }

  submitData = (event) => {
    this.props.clearAlert();

    event.preventDefault();

    const { email, password } = this.state;

    if (email && password) {
      this.setState({
        blankFields: true,
      });
      this.props.login(email, password);
    } else {
      this.setState({
        blankFields: false,
      });
    }
  };

  render() {
    const { email, password } = this.state.formErrors;
    const { alert } = this.props;
    const { blankFields, open } = this.state;

    var msgErr = "";
    var blankErr = "";

    if (alert.message && alert.message.includes("401")) {
      msgErr = "Email or Password is Invalid. Try again!";
    } else if (alert.type === "alert-success") {
      msgErr = "Successfully Logged In!";
    } else {
      msgErr = "Something went wrong";
    }

    if (!blankFields) {
      blankErr = "All Fields are required";
    }

    return (
      <>
        <div
          style={{ backgroundColor: "#f8fafb", padding: "1%", marginTop: "5%" }}
        >
          <div className="content mt-4">
            <div className="row">
              <Toaster
                position="top-center"
                reverseOrder={false}
                containerStyle={{ padding: "2%" }}
                toastOptions={{
                  // Define default options
                  className: "",
                  duration: 5000,
                  style: {
                    background: "#363636",
                    color: "#fff",
                  },

                  // Default options for specific types
                  success: {
                    duration: 3000,
                    theme: {
                      primary: "black",
                      secondary: "green",
                    },
                  },
                  error: {
                    duration: 3000,
                    theme: {
                      primary: "red",
                      secondary: "black",
                    },
                  },
                }}
              />

              <div className="col-md-5 order-md-2 mt-4">
                <img
                  src="/images/undraw_file_sync_ot38.png"
                  alt="Image"
                  className="img-fluid"
                />
              </div>

              <div className="col-md-6 contents ">
                <div className="row justify-content-center">
                  <div className="col-md-8">
                    <div className="text-center mb-3">
                      {alert.message && (
                        <div className={`alert ${alert.type}`}>{msgErr}</div>
                      )}
                      {!blankFields && (
                        <div
                          className={`alert`}
                          style={{ color: "white", backgroundColor: "#dc3545" }}
                        >
                          {blankErr}
                        </div>
                      )}
                    </div>
                    <div className="mb-4">
                      <h3>
                        Sign In to <strong>Sovica</strong>
                      </h3>
                      <p style={{ color: "#28a745" }} className="mb-4">
                        Towards a Greener Earth.
                      </p>
                    </div>
                    <form action="#" method="post">
                      <div
                        className={`form-group first ${this.errorClass(
                          this.state.formErrors.email
                        )}`}
                      >
                        <label htmlFor="email">Email</label>
                        <input
                          type="email"
                          placeholder="Enter Email"
                          className="form-control"
                          name="email"
                          onChange={this.handleInput}
                          value={this.state.email}
                          required={true}
                        />

                        <div className="text-left ml-2">
                          {/* {email ?
                                                    <div style={{ color: 'red', fontSize: 'smaller' }}>email {email}</div> : ''
                                                } */}
                        </div>
                      </div>

                      <div
                        className={`form-group last mb-4 ${this.errorClass(
                          this.state.formErrors.password
                        )}`}
                      >
                        <label htmlFor="password">Password</label>
                        <input
                          type="password"
                          className="form-control"
                          name="password"
                          onChange={this.handleInput}
                          placeholder="Enter password"
                          value={this.state.password}
                          required={true}
                        />

                        <div className="text-left ml-2">
                          {/* {password ?
                                                    <div style={{ color: 'red', fontSize: 'smaller' }}>password {password}</div> : ''
                                                } */}
                        </div>
                      </div>

                      <div className="d-flex mb-3 align-items-center">
                        <span className="ml-auto">
                          <button
                            type="button"
                            onClick={this.onOpenModal}
                            className="btn btn-link forgot-pass"
                          >
                            Forgot Password
                          </button>
                        </span>

                        <div>
                          <this.handleForgetPswdModal />
                        </div>
                      </div>

                      <input
                        type="submit"
                        value="Log In"
                        className="btn text-white btn-block btn-primary"
                        onClick={this.submitData}
                      />

                      <span className="d-block text-left my-2 text-muted">
                        Don't have an account?
                        <p className="text-sm mb-0">
                          {" "}
                          Individual account?{" "}
                          <Link to="/sign-up">
                            <span
                              className="text-sm mb-0"
                              style={{ color: "Highlight", cursor: "pointer" }}
                            >
                              Sign Up here
                            </span>
                          </Link>{" "}
                        </p>
                        <p className="text-sm">
                          {" "}
                          Corporate account?{" "}
                          <Link to="/create-organization">
                            <span
                              className="text-sm pt-0"
                              style={{ color: "Highlight", cursor: "pointer" }}
                            >
                              Sign Up here
                            </span>
                          </Link>
                        </p>
                      </span>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  const { alert } = state;

  return { loggingIn, alert };
}

export default connect(mapState, { login, logout, clearAlert })(Login);
