import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  useDataProvider,
  useVersion,
  useNotify,
  SelectInput,
  SimpleForm,
} from "react-admin";
import "../users/admin/cards/card.css";
import axios from "axios";
import html2canvas from "html2canvas";
import { useMediaQuery } from "@material-ui/core";
import { flipInY } from "react-animations";
import Radium, { StyleRoot } from "radium";
import { useParams } from "react-router-dom";
import { NODE_API } from "../../config";

const useStyles = makeStyles({
  flex: { display: "flex" },
  flexColumn: { display: "flex", flexDirection: "column" },
  leftCol: { flex: 1, marginRight: "1em" },
  rightCol: { flex: 1, marginLeft: "1em" },

  singleCol: { marginTop: "2em", marginBottom: "2em" },
  name: { display: "inline-block", marginLeft: "1em" },
  displayblock: { display: "inline-block" },
});
var firstName = "",
  lastName = "",
  title = "",
  email = "",
  contact = "",
  address = "";
const styles = {
  flipInY: {
    animation: "s 2s",
    animationName: Radium.keyframes(flipInY, "flipInY"),
  },
};
var idd;
var name;
const FrontView = ({ change }) => {
  const [state, setState] = useState({});
  const notify = useNotify();
  const { id } = useParams();
  useEffect(() => {
    fetchusercards().then((res) => {
      fetchcards();
    });
  }, []);

  const setDetails = (design) => {
    if (design) {
      document.getElementById("nameset").innerHTML = design.name;
      document.getElementById("designationset").innerHTML = design.designation;
      document.getElementById("empoyeeset").innerHTML = design.employeeid;
      document.getElementById("contactset").innerHTML = design.contactnumber;
      document.getElementById("emailset").innerHTML = design.email;
      document.getElementById("addressset").innerHTML = design.address;

      //for scanner
      var value = design.name;
      var convertvalue = value.split(" ");
      if (convertvalue.length === 2) {
        firstName = convertvalue[0];
        lastName = convertvalue[1];
      } else {
        firstName = design.name;
      }
      title = design.designation;
      contact = design.contactnumber.toString();
      email = design.email;
    }
    return;
  };
  const setDesign = (design) => {
    if (design) {
      design.map(function (element) {
        if (element.isDisable != true) {
          var topvalue = element.position.top + "px";

          document.getElementById(element.position.name).style.top = topvalue;

          var leftvalue = element.position.left + "px";
          document.getElementById(element.position.name).style.left = leftvalue;
          if (element.name === "mydivvl") {
            document.getElementById("mydivvl").style.width =
              element.position.width;
            document.getElementById("mydivvl").style.backgroundColor =
              element.position.backgroundColor;
            document.getElementById(element.position.name).style.cursor =
              "default";
          } else if (element.name === "mydivhl") {
            document.getElementById("mydivhl").style.height =
              element.position.height;
            document.getElementById("mydivhl").style.borderColor =
              element.position.borderColor;
            document.getElementById(element.position.name).style.cursor =
              "default";
          } else if (element.name != "logo") {
            document.getElementById(element.name).style.fontFamily =
              element.textStyle;
            document.getElementById(element.name).style.textDecoration =
              element.fontStyle.underline;
            document.getElementById(element.name).style.fontStyle =
              element.fontStyle.italic;
            document.getElementById(element.name).style.fontWeight =
              element.fontStyle.bold;
            document.getElementById(element.name).style.fontSize =
              element.fontSize;
            document.getElementById(element.name).style.color =
              element.fontColor;
            document.getElementById(element.position.name).style.cursor =
              "default";
          } else {
            if (element.backgroundtemplate != null)
              document.getElementById(
                "card"
              ).style.backgroundImage = `url(${element.backgroundtemplate})`;

            if (element.value != null)
              document.getElementById(
                "mydivlogo"
              ).style.backgroundImage = `url(${element.value})`;
            document.getElementById("mydivlogo").style.width =
              element.position.width;
            document.getElementById("mydivlogo").style.height =
              element.position.height;
            document.getElementById(element.position.name).style.border =
              "none";
            document.getElementById("logo").style.cursor = "default";
          }
        } else {
          if (element.name === "logo") {
            document.getElementById("mydivlogo").style.visibility = "hidden";
            document.getElementById(
              "card"
            ).style.backgroundImage = `url(${element.backgroundtemplate})`;
          } else if (element.name === "vlset") {
            document.getElementById("mydivvl").style.visibility = "hidden";
          } else if (element.name === "hlset") {
            document.getElementById("mydivhl").style.visibility = "hidden";
          } else {
            document.getElementById(element.name).style.visibility = "hidden";
          }
        }
      });
    }
    return;
  };

  const fetchcards = async function () {
    let user = JSON.parse(sessionStorage.getItem("user"));
    let token;

    if (user && user.validator) {
      token = user.validator;
    } else {
      token = {};
    }

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InZydXNoYWxpLnBAYWlreWFtZWRnZS5jb20iLCJ1c2VyaWQiOjE0MjQsImlhdCI6MTcyMjkyNTYzNiwiZXhwIjoxNzI1NTE3NjM2fQ.0vwGUiWHtwBqkxg2r3nK6Uq9w9SmDZ9s6qy-1bTCq_o",
      },
    };
    // requestOptions
    await axios(`${NODE_API}/card/business/design/${id}`, requestOptions)
      .then((res) => {
        document.getElementById("requestp").style.display = "none";
        document.getElementById("requestr").style.display = "none";
        document.getElementById("requesta").style.display = "none";
        document.getElementById("requestd").style.display = "none";
        document.getElementById("sharedivqr").style.display = "block";
        document.getElementById("card").style.display = "block";
        if (res && res.data.CardDetails != "Not found") {
          setDesign(res.data[0].front);
          fetchdetailsoncard();
        } else {
          document.getElementById("sharedivqr").style.display = "none";
          document.getElementById("card").style.display = "none";
          document.getElementById("requesta").style.display = "block";
        }
      })
      .catch((e) => {
        notify(`Something went wrong try again later`, { type: "warning" });
      });
  };

  const fetchdetailsoncard = async function () {
    let user = JSON.parse(sessionStorage.getItem("user"));
    let token;

    if (user && user.validator) {
      token = user.validator;
    } else {
      token = {};
    }

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InZydXNoYWxpLnBAYWlreWFtZWRnZS5jb20iLCJ1c2VyaWQiOjE0MjQsImlhdCI6MTcyMjkyNTYzNiwiZXhwIjoxNzI1NTE3NjM2fQ.0vwGUiWHtwBqkxg2r3nK6Uq9w9SmDZ9s6qy-1bTCq_o",
      },
    };

    // requestOptions
    await axios(`${NODE_API}/card/business/${id}`, requestOptions)
      .then((res) => {
        if (res && res.data.CardDetails != "Not found") {
          setDetails(res.data[0]);
          //   generateQRCode()
          if (res.data[0].is_approved === "T") {
            document.getElementById("sharedivqr").style.visibility = "visible";
          } else if (res.data[0].is_approved === "R") {
            document.getElementById("sharedivqr").style.display = "none";
            document.getElementById("card").style.display = "none";
            document.getElementById("requestr").style.display = "block";
          } else if (res.data[0].is_approved === "P") {
            document.getElementById("sharedivqr").style.display = "none";
            document.getElementById("card").style.display = "none";
            document.getElementById("requestp").style.display = "block";
          }
        } else {
          document.getElementById("sharedivqr").style.display = "none";
          document.getElementById("card").style.display = "none";
          document.getElementById("requestp").style.display = "block";
        }
        return;
      })
      .catch((e) => {
        notify(`Something went wrong try again later`, { type: "warning" });
      });
  };

  const fetchusercards = async function () {
    let user = JSON.parse(sessionStorage.getItem("user"));
    let token;
    if (user && user.validator) {
      token = user.validator;
    } else {
      token = {};
    }

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InZydXNoYWxpLnBAYWlreWFtZWRnZS5jb20iLCJ1c2VyaWQiOjE0MjQsImlhdCI6MTcyMjkyNTYzNiwiZXhwIjoxNzI1NTE3NjM2fQ.0vwGUiWHtwBqkxg2r3nK6Uq9w9SmDZ9s6qy-1bTCq_o",
      },
    };

    // requestOptions
    await axios(`${NODE_API}/card/business/user/organization`, requestOptions)
      .then((res) => {
        document.getElementById("requestp").style.display = "none";
        document.getElementById("requestr").style.display = "none";
        document.getElementById("requesta").style.display = "none";
        document.getElementById("requestd").style.display = "none";
        if (res && res.data.CardDetails != "Not found") {
          var organizationlist = [];
          res.data.map((val) => {
            organizationlist.push({
              id: val.organization_id,
              value: val.orgName,
              label: val.orgName,
              name: val.orgName,
            });
            idd = val.organization_id;
            name = val.orgName;
          });

          setState((state) => ({
            ...state,
            organizationlist: organizationlist,
          }));
        } else {
          document.getElementById("sharedivqr").style.display = "none";
          document.getElementById("card").style.display = "none";
          document.getElementById("requesta").style.display = "block";
        }
      })
      .catch((e) => {
        notify(`Something went wrong try again later`, { type: "warning" });
      });
  };
  const handleInput = async (event) => {
    idd = event.target.value;
    await fetchcards();
    // generateQRCode()
    //  await generateQRCode()
  };

  return (
    <>
      {state.organizationlist && state.organizationlist.length > 1 ? (
        <SimpleForm toolbar={false}>
          <SelectInput
            placeholder="Select organization"
            source="orgSelection"
            label="Select organization"
            fullWidth={true}
            required={true}
            defaultValue={idd}
            onChange={handleInput}
            style={{ width: "500px" }}
            choices={state.organizationlist}
          />
        </SimpleForm>
      ) : (
        <></>
      )}

      <div className="main">
        <div className="setting">
          <StyleRoot style={{ marginTop: "2em" }}>
            <div
              className="card"
              id="card"
              style={{ marginTop: "1em", ...styles.flipInY }}
              onClick={change}
            >
              <span id="mydivlogo" className="mydivlogo">
                <div className="logo" id="logo"></div>
              </span>
              <span id="mydivname">
                <p className="name" id="nameset"></p>
              </span>
              <span id="mydivslogan">
                <p className="slogan" id="sloganset"></p>
              </span>
              <span id="mydivcontact">
                <p className="contact" id="contactset"></p>
              </span>
              <span id="mydivdesignation">
                <p className="designation" id="designationset"></p>
              </span>
              <span id="mydivempoyeeid">
                <p className="empoyee" id="empoyeeset"></p>
              </span>
              <span id="mydivaddress">
                <p className="address" id="addressset"></p>
              </span>
              <span id="mydivemail">
                <p className="email" id="emailset"></p>
              </span>
              <span id="mydivhl">
                <hr className="hl" id="hlset" />
              </span>
              <span id="mydivvl">
                <p className="vl" id="vlset"></p>
              </span>
            </div>
          </StyleRoot>
        </div>
        <div className="setting " id="sharedivqr" style={{ marginTop: "2em" }}>
          {/* <img id='qrImage' style={{ display: 'inline' }}
              src='https://chart.googleapis.com/chart?cht=qr&choe=UTF-8&chs=200x200&chl=http%3A%2F%2sovica.in' />
           */}
        </div>
      </div>

      <h6
        id="requesta"
        style={{
          display: "inline",
          width: "100%",
          margin: "3%",
          color: "gray",
        }}
      >
        Wait for organization to design a card.
      </h6>
      <h6
        id="requestd"
        style={{
          display: "inline",
          width: "100%",
          margin: "3%",
          color: "gray",
        }}
      >
        oops !!! you didn't connect to any organization.
      </h6>
      <h6
        id="requestp"
        style={{
          display: "inline",
          width: "100%",
          margin: "3%",
          color: "gray",
        }}
      >
        oops !!! you didn't connect to any organization or your request is not
        approved yet. Wait for approval.
      </h6>
      <h6
        id="requestr"
        style={{
          display: "inline",
          width: "100%",
          margin: "3%",
          color: "gray",
        }}
      >
        Your approval request is rejected.Send request again with correct data.
      </h6>
      <a href="#" id="link" download="visiting card.png"></a>
    </>
  );
};
const BackView = ({ change }) => {
  const notify = useNotify();
  const { id } = useParams();

  useEffect(() => {
    fetchcards();
  });

  const setDesign = (design) => {
    if (design) {
      design.map(function (element) {
        if (element.isDisable != true) {
          var topvalue = element.position.top + "px";

          document.getElementById(element.position.name).style.top = topvalue;
          var leftvalue = element.position.left + "px";
          document.getElementById(element.position.name).style.left = leftvalue;
          if (element.name === "mydivvl") {
            document.getElementById("mydivvl").style.width =
              element.position.width;
            document.getElementById("mydivvl").style.backgroundColor =
              element.position.backgroundColor;
            document.getElementById(element.position.name).style.cursor =
              "default";
          } else if (element.name === "mydivhl") {
            document.getElementById("mydivhl").style.height =
              element.position.height;
            document.getElementById("mydivhl").style.borderColor =
              element.position.borderColor;
            document.getElementById(element.position.name).style.cursor =
              "default";
          } else if (element.name != "logo") {
            document.getElementById(element.name).innerHTML = element.value;
            document.getElementById(element.name).style.fontFamily =
              element.textStyle;
            document.getElementById(element.name).style.textDecoration =
              element.fontStyle.underline;
            document.getElementById(element.name).style.fontStyle =
              element.fontStyle.italic;
            document.getElementById(element.name).style.fontWeight =
              element.fontStyle.bold;
            document.getElementById(element.name).style.fontSize =
              element.fontSize;
            document.getElementById(element.name).style.color =
              element.fontColor;
            document.getElementById(element.position.name).style.cursor =
              "default";
            if (element.name === "nameset") {
              var value = element.value;
              var convertvalue = value.split(" ");
              if (convertvalue.length === 2) {
                firstName = convertvalue[0];
                lastName = convertvalue[1];
              } else {
                firstName = element.value;
              }
            }
            if (element.name === "sloganset") {
              title = element.value;
            }
            if (element.name === "contactset") {
              contact = element.value;
            }
            if (element.name === "emailset") {
              email = element.value;
            }
            if (element.name === "addressset") {
              address = element.value;
            }
          } else {
            console.log("element.value " + element.backgroundtemplate);
            if (element.backgroundtemplate != null)
              document.getElementById(
                "card"
              ).style.backgroundImage = `url(${element.backgroundtemplate})`;

            if (element.value != null)
              document.getElementById(
                "mydivlogo"
              ).style.backgroundImage = `url(${element.value})`;
            document.getElementById("mydivlogo").style.width =
              element.position.width;
            document.getElementById("mydivlogo").style.height =
              element.position.height;
            document.getElementById(element.position.name).style.border =
              "none";
            document.getElementById("logo").style.cursor = "default";
          }
        } else {
          if (element.name === "logo") {
            document.getElementById("mydivlogo").style.visibility = "hidden";
            document.getElementById(
              "card"
            ).style.backgroundImage = `url(${element.backgroundtemplate})`;
          } else if (element.name === "vlset") {
            document.getElementById("mydivvl").style.visibility = "hidden";
          } else if (element.name === "hlset") {
            document.getElementById("mydivhl").style.visibility = "hidden";
          } else {
            document.getElementById(element.name).style.visibility = "hidden";
          }
        }
      });
    }
    return;
  };

  const fetchcards = useCallback(async () => {
    let user = JSON.parse(sessionStorage.getItem("user"));
    let token;
    if (user && user.validator) {
      token = user.validator;
    } else {
      token = {};
    }
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InZydXNoYWxpLnBAYWlreWFtZWRnZS5jb20iLCJ1c2VyaWQiOjE0MjQsImlhdCI6MTcyMjkyNTYzNiwiZXhwIjoxNzI1NTE3NjM2fQ.0vwGUiWHtwBqkxg2r3nK6Uq9w9SmDZ9s6qy-1bTCq_o",
      },
    };
    // requestOptions
    await axios(`${NODE_API}/card/business/design/${id}`, requestOptions)
      .then((res) => {
        document.getElementById("requestp").style.display = "none";
        document.getElementById("requestr").style.display = "none";
        document.getElementById("requesta").style.display = "none";
        document.getElementById("requestd").style.display = "none";
        document.getElementById("card").style.display = "block";

        if (res && res.data.CardDetails != "Not found") {
          setDesign(res.data[0].back);
        } else {
          document.getElementById("sharedivqr").style.display = "none";
          document.getElementById("card").style.display = "none";
          document.getElementById("requesta").style.display = "block";
        }
      })
      .catch((e) => {
        notify(`Something went wrong try again later`, { type: "warning" });
      });
  }, []);

  return (
    <>
      <div className="main">
        <div className="setting">
          <StyleRoot style={{ marginTop: "2em" }}>
            <div
              className="card"
              id="card"
              style={{ marginTop: "1em", ...styles.flipInY }}
              onClick={change}
            >
              <span id="mydivlogo" className="mydivlogo">
                <div className="logo" id="logo"></div>
              </span>
              <span id="mydivname">
                <p className="name" id="nameset"></p>
              </span>
              <span id="mydivslogan">
                <p className="slogan" id="sloganset"></p>
              </span>
              <span id="mydivcontact">
                <p className="contact" id="contactset"></p>
              </span>
              <span id="mydivdesignation">
                <p className="designation" id="designationset"></p>
              </span>
              <span id="mydivempoyeeid">
                <p className="empoyee" id="empoyeeset"></p>
              </span>
              <span id="mydivaddress">
                <p className="address" id="addressset"></p>
              </span>
              <span id="mydivemail">
                <p className="email" id="emailset"></p>
              </span>
              <span id="mydivhl">
                <hr className="hl" id="hlset" />
              </span>
              <span id="mydivvl">
                <p className="vl" id="vlset"></p>
              </span>
            </div>
          </StyleRoot>
        </div>
        <div
          className="setting "
          id="sharedivqr"
          style={{ marginTop: "2em" }}
        ></div>
      </div>
     
    
      
     
      <a href="#" id="link" download="visiting card.png"></a>
    </>
  );
};
const ViewCard = (props) => {
  const [state, setState] = React.useState({
    view: 0,
  });
  const change = () => {
    if (state.view == 0) {
      setState((state) => ({
        ...state,

        view: 1,
      }));
    } else {
      setState((state) => ({
        ...state,

        view: 0,
      }));
    }
  };
  return (
    <>
      {state.view === 0 ? (
        <FrontView change={change} />
      ) : (
        <BackView change={change} />
      )}
    </>
  );
};
export default ViewCard;
