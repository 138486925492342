import React, { useState, useEffect, useRef } from "react";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import "leaflet/dist/leaflet.css";
import { Dialog } from "@material-ui/core";

const MapModal = ({ onClose, onSelectCoordinates }) => {
  const [position, setPosition] = useState([18.5233617, 73.7779519]);
  const [markerPosition, setMarkerPosition] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [mapInstance, setMapInstance] = useState(null);
  const [zoomControl, setZoomControl] = useState(6);
  const mapRef = useRef();

  useEffect(() => {
    delete L.Icon.Default.prototype._getIconUrl;
    L.Icon.Default.mergeOptions({
      iconRetinaUrl:
        "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png",
      iconUrl:
        "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
      shadowUrl:
        "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
    });
  }, []);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((pos) => {
      const { latitude, longitude } = pos.coords;
      console.log("setting marker position in useEffect", latitude);

      setPosition([latitude, longitude]);
    });
  }, []);

  useEffect(() => {
    console.log("useEffect markerPosition::", markerPosition);
    console.log("useEffect position::", position);
  }, [markerPosition, position]);

  const handleSearch = () => {
    const provider = new OpenStreetMapProvider();

    provider.search({ query: searchQuery }).then((results) => {
      if (results && results.length > 0) {
        const { x: lng, y: lat } = results[0];
        setMarkerPosition([lat, lng]);
        setPosition([lat, lng]);
        setZoomControl(16);
        console.log("Searched location::", results[0]);
      }
    });
  };

  const onMapLoad = (map) => {
    console.log("onMapLoad::", map);
    console.log("onMapLoad:mapRef::", mapRef);

    if (map) {
      if (!markerPosition) {
        setZoomControl(map.zoom);
        setPosition([map.center[0], map.center[1]]);
      }
    }
  };

  const handleGoogleMapRedirect = () => {
    const userConfirmed = window.confirm(
      "Google Maps will open in a new tab. Please search for the location, copy the latitude and longitude, and paste them into the input fields."
    );

    if (userConfirmed) {
      const lat = position ? position[0] : 0;
      const lng = position ? position[1] : 0;
      const googleMapsUrl = `https://www.google.com/maps`;
      // const googleMapsUrl = `https://www.google.com/maps?q=${lat},${lng}`;
      window.open(googleMapsUrl, "_blank");
    }
  };

  const handleUserEnteredLatLng = (e) => {
    console.log("handleUserEnteredLatLng", e.target.value);
    onSelectCoordinates(e.target.value);
  };
  const handleSave = () => {
    if (markerPosition) {
      onSelectCoordinates(markerPosition[0], markerPosition[1]);
    } else if (position) {
      onSelectCoordinates(position[0], position[1]);
    }
  };

  return (
    <Dialog
      open
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        style: {
          height: "90vh",
          width: "80vw",
          margin: "auto",
        },
      }}
    >
      <div className="map-modal d-flex flex-column" style={{ height: "100%" }}>
        <div className="search-bar p-2">
          <div className="row">
            <div className="col-10">
              <input
                type="text"
                value={searchQuery}
                className="form-control"
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search a location"
              />
            </div>
            <div className="col-2">
              <button className="btn btn-primary" onClick={handleSearch}>
                Search
              </button>
            </div>
          </div>
        </div>

        <Map
          center={
            markerPosition ? markerPosition : position ? position : [0, 0]
          }
          zoom={zoomControl}
          style={{ height: "500px", width: "100%" }}
          onViewportChange={onMapLoad}
          ref={mapRef}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />

          {(markerPosition || position) && (
            <Marker
              position={markerPosition ? markerPosition : position}
              draggable
              eventHandlers={{
                click: () => {
                  console.log("marker clicked");
                },
              }}
            >
              <Popup>
                {searchQuery ? "You searched here!" : "Marker placed here!"}
              </Popup>
            </Marker>
          )}
        </Map>

        <div className="button-group mt-auto p-2">
          <a
            style={{
              cursor: "pointer",
              textDecoration: "underline",
              color: "blue",
            }}
            onClick={handleGoogleMapRedirect}
          >
            Select Latitude & Longitude from Google Map
          </a>
          <div className="row m-1">
            <span className="co-3">latitude, longitude</span>
            <input
              style={{ marginLeft: 4 }}
              type="text"
              className="col-5"
              placeholder="latitude, longitude"
              onChange={handleUserEnteredLatLng}
            />
          </div>
          <div className="d-flex justify-content-end">
            <button className="btn btn-success me-2 mr-2" onClick={handleSave}>
              Save
            </button>
            <button className="btn btn-secondary ml-2" onClick={onClose}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default MapModal;
