//User registration
import React, { Component } from "react";
import { connect } from "react-redux";
import { register, sendOTPMail } from "../../components/redux/action/user";
import { clearAlert } from "../../components/redux/action/alert";
import { validateField, errorClass } from "../../utils/Validations";
import { OTPGenerator, OTPEncryption } from "../..//utils/encrypt";
import { history } from "../../helpers/history";
import Alert from "react-bootstrap/Alert";
import { Link } from "react-router-dom";

class Register extends Component {
  constructor(props) {
    super(props);

    history.listen((location, action) => {
      this.props.clearAlerts();
    });

    this.state = {
      email: "",
      password: "",
      username: "",
      confirmPassword: null,
      otp: "",
      formErrors: {
        username: "",
        email: "",
        password: "",
        confirmPassword: "",
        otp: "",
      },
      emailValid: false,
      usernameValid: false,
      passwordValid: true,
      confirmPasswordValid: false,
      otpValid: false,
      formValid: false,
      generatedOTP: "",
      isEmailVerify: false,
      enableDiv: true,
      otpError: null,
    };
  }

  handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    var checkValidation;

    this.props.clearAlert();

    name === "confirmPassword"
      ? (checkValidation = validateField(
          name,
          value,
          this.state,
          this.state.password
        ))
      : name === "password"
      ? (checkValidation = validateField(
          name,
          value,
          this.state,
          this.state.confirmPassword
        ))
      : (checkValidation = validateField(name, value, this.state, false));

    this.setState(
      {
        [name]: value,
        formErrors: checkValidation.formErrors,
        emailValid: checkValidation.emailValid,
        usernameValid: checkValidation.usernameValid,
        confirmPasswordValid: checkValidation.confirmPasswordValid,
      },
      () => {
        this.validateForm();
      }
    );
  };

  validateForm() {
    this.setState({
      formValid:
        this.state.usernameValid &&
        this.state.emailValid &&
        this.state.passwordValid &&
        this.state.otpValid &&
        this.state.confirmPasswordValid,
    });
  }

  verifyEmail = (event) => {
    event.preventDefault();

    const { email } = this.state;

    if (email) {
      this.setState(
        {
          generatedOTP: OTPGenerator(),
          isEmailVerify: true,
        },
        () => {
          var encryptedKey = OTPEncryption(this.state.generatedOTP).toString();
          this.props.sendOTPMail(email, encryptedKey);
        }
      );
    } else {
      const name = event.target.name;
      const value = event.target.value;
      this.setState({ [name]: value }, () => {
        validateField(name, value);
      });
    }
  };

  verifyOTP = (event) => {
    event.preventDefault();

    this.props.clearAlert();

    const { otp, generatedOTP } = this.state;

    if (otp === String(generatedOTP)) {
      this.setState({
        enableDiv: false,
        otpValid: true,
        otpError: 0,
      });
    } else {
      this.setState({
        otpError: 1,
      });
    }
  };

  submitData = (event) => {
    event.preventDefault();

    this.props.clearAlert();

    const { email, password, username } = this.state;

    var user = {
      email: email,
      name: username,
      password: password,
    };

    this.setState({
      otpError: "",
    });

    if (this.state.formValid && this.state.username) {
      this.props.register(user);
    }
  };

  setShow = () => {
    this.setState({
      enableDiv: false,
      otpValid: false,
      isEmailVerify: false,
    });
    history.push("/sign-up");
  };

  emailExistAlert = () => {
    this.props.clearAlert();

    return (
      <div className="row justify-content-center">
        <div className="col-7 justify-content-center text-center p-2">
          <Alert
            className="p-0 m-0 text-center"
            variant="danger"
            show={!this.state.enableDiv}
          >
            <div className="d-flex m-1 justify-content-end">
              <i
                className="fa fa-times"
                aria-hidden="true"
                onClick={() => this.setShow(false)}
              ></i>
            </div>

            <Alert.Heading>Email Already Exists</Alert.Heading>
          </Alert>
        </div>
      </div>
    );
  };

  render() {
    const { password, name, confirmPassword, username } = this.state.formErrors;
    const { alert } = this.props;
    const { otpError } = this.state;

    var msgErr = null;

    if (alert.message && alert.message.includes("401")) {
      msgErr = "Invalid Credentials !!!";
    } else if (alert.type === "alert-success") {
      msgErr = alert.message;
    } else if (
      alert.message &&
      alert.message.includes("Email Already Exists") &&
      this.state.email
    ) {
      msgErr = "Email Already Exists";
    } else {
      msgErr = "";
    }

    return (
      <div
        style={{ backgroundColor: "#f8fafb", padding: "1%", marginTop: "2%" }}
      >
        <div className="content mt-4">
          <div className="row">
            <div className="col-md-5 order-md-2 mt-4">
              <img
                src="/images/undraw_file_sync_ot38.png"
                alt="Image"
                className="img-fluid"
              />
            </div>
            <div className="col-md-6 contents">
              <div className="row justify-content-center">
                <div className="col-md-8">
                  <div className="text-center mb-3">
                    {alert.message && (
                      <div className={`alert ${alert.type}`}>{msgErr}</div>
                    )}

                    {otpError === 0 ? (
                      <div className="text-white bg-success p-2">
                        OTP verified successfully
                      </div>
                    ) : otpError === 1 ? (
                      <div className="text-white bg-danger p-2">
                        Enter valid OTP
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="mb-2">
                    <h3>
                      Sign Up to <strong>Sovica</strong>
                    </h3>
                    <p style={{ color: "#28a745" }} className="mb-2">
                      Towards a Greener Earth.
                    </p>
                    <div className="row">
                      <div
                        class="col"
                        style={{ borderBottom: "1px blue solid" }}
                      >
                        <Link to="/sign-up">
                          <p className="text-center text-sm mb-2 text-blue">
                            User Register
                          </p>
                        </Link>
                      </div>
                      <div class="col">
                        <Link to="/create-organization">
                          <button
                            type="button"
                            class="btn btn-primary btn-sm btn-block"
                          >
                            Register Organization
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <form>
                    <div className={`form-group mt-0`}>
                      <label htmlFor="password">Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="username"
                        required
                        onChange={this.handleInput}
                        placeholder="Name"
                      />
                      {username ? (
                        <small style={{ color: "red", fontSize: "smaller" }}>
                          {username}
                        </small>
                      ) : (
                        ""
                      )}
                    </div>

                    <div>{this.disableEmail()}</div>

                    <div className={`form-group`}>
                      <label htmlFor="password">Password</label>
                      <input
                        type="password"
                        className="form-control"
                        name="password"
                        required
                        onChange={this.handleInput}
                        placeholder="Password"
                      />
                      {password ? (
                        <small style={{ color: "red", fontSize: "smaller" }}>
                          {password}
                        </small>
                      ) : (
                        ""
                      )}
                    </div>
                    <div
                      className={`form-group last mb-4 ${errorClass(
                        this.state.formErrors.confirmPassword
                      )}`}
                    >
                      <label htmlFor="confirmPassword">Confirm Password</label>
                      <input
                        type="password"
                        className="form-control"
                        name="confirmPassword"
                        required
                        onChange={this.handleInput}
                        placeholder="Confirm Password"
                      />
                      {confirmPassword ? (
                        <small style={{ color: "red", fontSize: "smaller" }}>
                          {" "}
                          {confirmPassword}{" "}
                        </small>
                      ) : (
                        ""
                      )}
                    </div>

                    <input
                      type="submit"
                      disabled={!this.state.formValid}
                      value="Register"
                      onClick={this.submitData}
                      className="btn text-white btn-block btn-primary"
                    />
                    <div className="row justify-content-between">
                      <span className="d-block text-left my-2 mb-0 text-muted">
                        Already have an account?
                        <Link
                          to="/login"
                          style={{ color: "Highlight", cursor: "pointer" }}
                        >
                          {" "}
                          Login
                        </Link>{" "}
                        here
                      </span>
                      {/* <p className="text-sm">
                        <Link to="/create-organization">
                          Create a new organization?
                          <span
                            className="text-sm pt-0"
                            style={{ color: "Highlight", cursor: "pointer" }}
                          >
                            {" "}
                            Sign Up here
                          </span>
                        </Link>
                      </p> */}
                      {/* <div
                        className="d-block text-right my-2 mb-0 text-muted rounded p-1"
                        style={{ backgroundColor: "#4caf50" }}
                      >
                        <Link
                          to="/create-organization"
                          style={{ color: "white", cursor: "pointer" }}
                        >
                          {" "}
                          Register your organization{" "}
                        </Link>
                      </div> */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  RenderOTPDiv = () => {
    const { otp, email } = this.state.formErrors;

    return (
      <>
        <div
          className={`form-group form-group-sm ${errorClass(
            this.state.formErrors.email
          )}`}
        >
          <label htmlFor="inputEmail">Email</label>

          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder="Email"
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
              disabled
              name="email"
              onChange={this.handleInput}
              id="inputEmail"
              required
            />
          </div>
        </div>
        <div
          className={`form-group form-group-sm ${errorClass(
            this.state.formErrors.otp
          )}`}
        >
          <label htmlFor="inputOTP">OTP</label>

          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder="Enter OTP"
              name="otp"
              onChange={this.handleInput}
              id="inputOTP"
              required
            />
            <span className="input-group-addon input-group-addon-btn bg-white">
              <button
                className="btn btn-outline-primary"
                type="button"
                onClick={this.verifyOTP}
              >
                Verify
              </button>
            </span>
          </div>
          {otp ? (
            <small style={{ color: "red", fontSize: "smaller" }}>
              OTP {otp}
            </small>
          ) : (
            ""
          )}
        </div>
      </>
    );
  };

  RenderEmailDiv = () => {
    const { email } = this.state.formErrors;

    return (
      <>
        <div
          className={`form-group form-group-sm ${errorClass(
            this.state.formErrors.email
          )}`}
        >
          <label htmlFor="inputEmail">Email</label>

          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder="Email"
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
              name="email"
              onChange={this.handleInput}
              id="inputEmail"
              required
            />
            <span className="input-group-addon input-group-addon-btn bg-white">
              <button
                className="btn btn-outline-primary"
                name="email"
                type="button"
                onClick={this.verifyEmail}
              >
                Verify
              </button>
            </span>
          </div>
          {!this.state.isEmailVerify ? (
            <small className="text-warning" style={{ fontSize: "smaller" }}>
              You need to use your personal mail address for registration.
            </small>
          ) : (
            <></>
          )}
          {email ? (
            <small style={{ color: "red", fontSize: "smaller" }}>
              email {email}
            </small>
          ) : (
            ""
          )}
        </div>
      </>
    );
  };

  disableEmail = () => {
    const { enableDiv, isEmailVerify } = this.state;

    if (enableDiv) {
      return !isEmailVerify ? this.RenderEmailDiv() : this.RenderOTPDiv();
    } else {
      return <></>;
    }
  };
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  const { alert } = state;

  return { loggingIn, alert };
}

export default connect(mapState, { clearAlert, register, sendOTPMail })(
  Register
);
