import React, { useCallback, useEffect, useState } from "react";
import {
  TextInput,
  SimpleForm,
  useNotify,
  useRedirect,
  useVersion,
  Toolbar,
  SaveButton,
  useRefresh,
  SelectInput,
  ImageInput,
  ImageField,
  FormDataConsumer,
  Labeled,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery, Input, TextField } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import { IMAGE_API, NODE_API, USER_PROFILE_IMAGE } from "../../../../config";
import { Link, useHistory } from "react-router-dom";
import toast from "react-hot-toast";

export const styles = {
  inline1: {
    display: "inline-block",
    color: "black",
    width: "30%",
    marginLeft: "8%",
  },
  inline2: {
    display: "inline-block",
    color: "black",
    marginLeft: "5%",
    width: "30%",
  },
  inlineP1: {
    display: "inline-block",
    width: "30%",
    marginLeft: "8%",
    backgroundColor: "#ececec",
    margin: "1%",
    textAlign: "center",
    padding: "5px",
  },
  inlineP2: {
    display: "inline-block",
    color: "black",
    marginLeft: "5%",
    width: "30%",
    backgroundColor: "#ececec",
    margin: "1%",
    padding: "5px",
  },
  dropdown: { display: "inline-block", width: "30%", marginLeft: "8%" },
  location: { display: "inline-block", marginLeft: 20, width: "40%" },
  addbutton: { width: "30%", marginLeft: "43%" },

  imageDiv: { with: 544 },
  form: {
    marginLeft: "50%",
    display: "flex",
    justifyContent: "center",
  },
};

const useStyles = makeStyles(styles);

const EditCardDetails = (props) => {
  const redirect = useRedirect();
  const notify = useNotify();
  const classes = useStyles();
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const refresh = useRefresh();
  const history = useHistory();
  const [state, setState] = useState({ enableSaveBtn: true });
  const version = useVersion();
  var organizationlist = [];
  const { organizationDetails } = props;
  const [flag, setFlag] = useState(0);

  const setOrgDetailsCall = useCallback(async () => {
    if (organizationDetails.length == 1) {
      organizationDetails.map((val) => {
        setState((state) => ({
          ...state,
          city: val.city,
          orgName: val.orgName,
          name: val.name,
          orgDomain: val.domain,
          orgWebsite: val.website,
          orgAddress: val.address,
          email: val.email,
          contactnumber: val.contactnumber,
          employeeid: val.employeeid,
          designation: val.designation,
          is_approved: val.is_approved,
          orgId: val.org_id,
          userId: val.user_id,
        }));
      });
    } else {
      let filterArr = [];
      filterArr.push(organizationDetails[0]);

      filterArr.map((val) => {
        setState((state) => ({
          ...state,
          city: val.city,
          orgName: val.orgName,
          name: val.name,
          orgDomain: val.domain,
          orgWebsite: val.website,
          orgAddress: val.address,
          email: val.email,
          contactnumber: val.contactnumber,
          employeeid: val.employeeid,
          designation: val.designation,
          is_approved: val.is_approved,
          orgId: val.org_id,
          userId: val.user_id,
        }));
      });
    }
  });

  useEffect(() => {
    setOrgDetailsCall();
  }, [version]);

  if (organizationDetails) {
    organizationDetails.map((val) => {
      organizationlist.push({
        id: val.orgName,
        value: val.orgName,
        label: val.orgName,
        name: val.orgName,
      });
    });
  }

  async function updateBusinessCardRejectRequest(logoData, data, orgId) {
    let user = JSON.parse(sessionStorage.getItem("user"));
    let token, reqData;

    if (user && user.validator) {
      token = user.validator;
    } else {
      token = {};
    }

    if (state.is_approved == null) {
    }

    reqData = {
      name: data.name,
      designation: data.designation,
      contactnumber: data.contactnumber,
      organizationID: state.orgId,
      employeeid: data.employeeid,
    };

    if (logoData) {
      reqData.userImage = logoData;
    }

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      data: JSON.stringify(reqData),
    };

    return axios(
      `${NODE_API}/organization/users/corporate/card/P`,
      requestOptions
    )
      .then(async (res) => {
        if (res.data.status && res.data.status === "Database error") {
          notify(`Error Sending Request`, { type: "warning" });
          redirect("/");
        } else if (res.status && res.status === 200) {
          notify(`Your Request has been sent successfully`, {
            type: "success",
          });
          redirect("/");

          setState((state) => ({
            ...state,

            enableSaveBtn: false,
          }));
        } else {
          notify(`Something went wrong try again later`, { type: "warning" });
          redirect("/");
        }

        return res;
      })
      .catch((e) => {
        notify(`Something went wrong try again later`, { type: "warning" });
        redirect("/");
      });
  }

  const onsubmit = async (data) => {
    const { organizationID } = props;
    if (data && data.userImage) {
      var file = data.userImage;

      const reader = new FileReader();
      reader.readAsDataURL(file.rawFile);
      reader.onload = async () =>
        await updateBusinessCardRejectRequest(
          reader.result,
          data,
          organizationID
        );
      reader.onerror = () =>
        notify(`Something went wrong`, { type: "warning" });
    } else {
      await updateBusinessCardRejectRequest(null, data, organizationID);
    }
  };

  const onAcceptsubmit = (data) => {
    setState({
      ...state,
      editApproved: true,
    });
  };

  const RequestStatus = (status, orgNamePara, flags) => {
    const handleInput = (event) => {
      organizationDetails.map((val) => {
        if (val.orgName == event.target.value) {
          setState((state) => ({
            ...state,
            city: val.city,
            orgName: val.orgName,
            name: val.name,
            orgDomain: val.domain,
            orgWebsite: val.website,
            orgAddress: val.address,
            email: val.email,
            contactnumber: val.contactnumber,
            employeeid: val.employeeid,
            designation: val.designation,
            is_approved: val.is_approved,
            orgId: val.org_id,
            userId: val.user_id,
          }));
          setFlag(1);
        }
      });
    };

    const { editApproved } = state;

    if (status === "P") {
      return isXSmall && isSmall ? (
        <>
          <SimpleForm
            toolbar={
              <PostEditPending disable={true} status={status} props={props} />
            }
          >
            <SelectInput
              placeholder="Select organization"
              source="orgSelection"
              label="Select organization"
              required={true}
              defaultValue={state.orgName}
              onChange={handleInput}
              choices={organizationlist}
            />

            <Typography variant="h6" gutterBottom style={{ marginLeft: "8%" }}>
              Organization Form
            </Typography>

            <TextField
              InputProps={{ inputProps: { style: { color: "black" } } }}
              disabled
              value={state.orgName}
              autoFocus
              fullWidth={true}
              source="organizationname"
              label="Organization Name"
            />

            <TextField
              InputProps={{ inputProps: { style: { color: "black" } } }}
              disabled
              defaultValue={state.orgDomain}
              fullWidth={true}
              source="organizationdomain"
              label="Organization Domain"
            />

            <TextField
              InputProps={{ inputProps: { style: { color: "black" } } }}
              disabled
              defaultValue={state.orgWebsite}
              autoFocus
              fullWidth={true}
              source="organizationwebsite"
              label="Organization Website"
            />

            <TextField
              InputProps={{ inputProps: { style: { color: "black" } } }}
              disabled
              defaultValue={state.orgAddress}
              autoFocus
              fullWidth={true}
              source="organizationaddress"
              label="Organization Address"
            />

            <TextField
              InputProps={{ inputProps: { style: { color: "black" } } }}
              defaultValue={state.city}
              disabled
              source="location"
              fullWidth={true}
              label="Location"
            />

            <TextField
              InputProps={{ inputProps: { style: { color: "black" } } }}
              disabled
              defaultValue={state.employeeid}
              autoFocus
              source="employeeid"
              label="Employee Id"
              fullWidth={true}
              required={true}
            />

            <TextField
              InputProps={{ inputProps: { style: { color: "black" } } }}
              defaultValue={state.name}
              disabled
              source="name"
              fullWidth={true}
              label="Name"
            />

            <TextField
              InputProps={{ inputProps: { style: { color: "black" } } }}
              disabled
              defaultValue={state.email}
              autoFocus
              source="email"
              label="Employee Email"
              fullWidth={true}
              required={true}
            />

            <TextField
              InputProps={{ inputProps: { style: { color: "black" } } }}
              disabled
              defaultValue={state.designation}
              autoFocus
              source="designation"
              label="Designation"
              fullWidth={true}
              required={true}
            />

            <TextField
              InputProps={{ inputProps: { style: { color: "black" } } }}
              disabled
              defaultValue={state.contactnumber}
              autoFocus
              source="contactnumber"
              label="Contact Number"
              fullWidth={true}
              required={true}
            />
            <FormDataConsumer
              source="oldImage"
              style={{ height: "7%", width: "100%" }}
            >
              {({ formData, dispatch, ...rest }) => {
                if (!formData.organizationimg) {
                  return (
                    <Labeled label="Profile photo">
                      <img
                        src={`${USER_PROFILE_IMAGE}/${state.userId}/profile.png`} //check if userid exists in state
                        {...rest}
                        className="thumbnail"
                        width="10%"
                      />
                    </Labeled>
                  );
                }
              }}
            </FormDataConsumer>
          </SimpleForm>
        </>
      ) : (
        <>
          <SimpleForm
            toolbar={
              <PostEditPending disable={true} status={status} props={props} />
            }
          >
            <SelectInput
              placeholder="Select organization"
              source="orgSelection"
              label="Select organization"
              fullWidth={true}
              required={true}
              onChange={handleInput}
              defaultValue={state.orgName}
              formClassName={classes.dropdown}
              choices={organizationlist}
            />

            <Typography variant="h6" gutterBottom style={{ marginLeft: "8%" }}>
              Organization Form
            </Typography>

            <TextField
              InputProps={{ inputProps: { style: { color: "black" } } }}
              disabled
              value={state.orgName}
              autoFocus
              fullWidth={true}
              source="organizationname"
              label="Organization Name"
              formClassName={classes.inlineP1}
            />

            <TextField
              InputProps={{ inputProps: { style: { color: "black" } } }}
              disabled
              value={state.orgDomain}
              fullWidth={true}
              source="organizationdomain"
              label="Organization Domain"
              formClassName={classes.inlineP2}
            />

            <TextField
              InputProps={{ inputProps: { style: { color: "black" } } }}
              disabled
              value={state.orgWebsite}
              autoFocus
              fullWidth={true}
              source="organizationwebsite"
              label="Organization Website"
              formClassName={classes.inlineP1}
            />

            <TextField
              InputProps={{ inputProps: { style: { color: "black" } } }}
              disabled
              value={state.orgAddress}
              autoFocus
              fullWidth={true}
              source="organizationaddress"
              label="Organization Address"
              formClassName={classes.inlineP2}
            />

            <TextField
              InputProps={{ inputProps: { style: { color: "black" } } }}
              value={state.city}
              disabled
              source="location"
              fullWidth={true}
              label="Location"
              formClassName={classes.inlineP1}
            />

            <TextField
              InputProps={{ inputProps: { style: { color: "black" } } }}
              disabled
              value={state.employeeid}
              autoFocus
              source="employeeid"
              label="Employee Id"
              fullWidth={true}
              formClassName={classes.inlineP2}
              required={true}
            />

            <TextField
              InputProps={{ inputProps: { style: { color: "black" } } }}
              value={state.name}
              disabled
              source="name"
              fullWidth={true}
              label="Name"
              formClassName={classes.inlineP1}
            />

            <TextField
              InputProps={{ inputProps: { style: { color: "black" } } }}
              disabled
              value={state.email}
              autoFocus
              source="email"
              label="Employee Email"
              fullWidth={true}
              formClassName={classes.inlineP2}
              required={true}
            />

            <TextField
              InputProps={{ inputProps: { style: { color: "black" } } }}
              disabled
              value={state.designation}
              autoFocus
              source="designation"
              label="Designation"
              fullWidth={true}
              formClassName={classes.inlineP1}
              required={true}
            />

            <TextField
              InputProps={{ inputProps: { style: { color: "black" } } }}
              disabled
              value={state.contactnumber}
              autoFocus
              source="contactnumber"
              label="Contact Number"
              fullWidth={true}
              formClassName={classes.inlineP2}
              required={true}
            />
            <FormDataConsumer
              source="oldImage"
              style={{ height: "7%", width: "100%" }}
            >
              {({ formData, dispatch, ...rest }) => {
                if (!formData.organizationimg) {
                  return (
                    <Labeled label="Profile photo">
                      <img
                        src={`${USER_PROFILE_IMAGE}/${state.userId}/profile.png`} //check if userid exists in state
                        {...rest}
                        className="thumbnail"
                        width="10%"
                      />
                    </Labeled>
                  );
                }
              }}
            </FormDataConsumer>
          </SimpleForm>
        </>
      );
    } else if (status === "R") {
      return isXSmall && isSmall ? (
        <>
          <SimpleForm
            toolbar={
              <PostEditReject disable={true} status={status} props={props} />
            }
          >
            <SelectInput
              placeholder="Select organization"
              source="orgSelection"
              label="Select organization"
              fullWidth={true}
              required={true}
              defaultValue={state.orgName}
              onChange={handleInput}
              choices={organizationlist}
            />

            <Typography variant="h6" gutterBottom style={{ marginLeft: "8%" }}>
              Organization Form
            </Typography>

            <TextInput
              InputProps={{ inputProps: { style: { color: "black" } } }}
              disabled
              defaultValue={state.orgName}
              autoFocus
              fullWidth={true}
              source="organizationname"
              label="Organization Name"
            />

            <TextInput
              InputProps={{ inputProps: { style: { color: "black" } } }}
              disabled
              defaultValue={state.orgDomain}
              fullWidth={true}
              source="organizationdomain"
              label="Organization Domain"
            />

            <TextInput
              InputProps={{ inputProps: { style: { color: "black" } } }}
              disabled
              defaultValue={state.orgWebsite}
              autoFocus
              fullWidth={true}
              source="organizationwebsite"
              label="Organization Website"
            />

            <TextInput
              InputProps={{ inputProps: { style: { color: "black" } } }}
              defaultValue={state.city}
              disabled
              source="location"
              fullWidth={true}
              label="Location"
            />

            <TextInput
              InputProps={{ inputProps: { style: { color: "black" } } }}
              disabled
              defaultValue={state.orgAddress}
              autoFocus
              fullWidth={true}
              source="organizationaddress"
              label="Organization Address"
            />

            <TextInput
              InputProps={{ inputProps: { style: { color: "black" } } }}
              disabled
              defaultValue={state.employeeid}
              autoFocus
              source="employeeid"
              label="Employee Id"
              fullWidth={true}
              required={true}
            />

            <TextInput
              InputProps={{ inputProps: { style: { color: "black" } } }}
              defaultValue={state.name}
              source="name"
              autoFocus
              fullWidth={true}
              label="Name"
            />

            <TextInput
              InputProps={{ inputProps: { style: { color: "black" } } }}
              disabled
              defaultValue={state.email}
              source="email"
              label="Employee Email"
              fullWidth={true}
              required={true}
            />

            <TextInput
              InputProps={{ inputProps: { style: { color: "black" } } }}
              defaultValue={state.designation}
              source="designation"
              label="Designation"
              fullWidth={true}
              required={true}
            />

            <TextInput
              InputProps={{ inputProps: { style: { color: "black" } } }}
              defaultValue={state.contactnumber}
              source="contactnumber"
              label="Contact Number"
              fullWidth={true}
              required={true}
            />
            <FormDataConsumer
              source="oldImage"
              style={{ height: "7%", width: "100%" }}
            >
              {({ formData, dispatch, ...rest }) => {
                if (!formData.organizationimg) {
                  return (
                    <Labeled label="Profile photo">
                      <img
                        src={`${USER_PROFILE_IMAGE}/${state.userId}/profile.png`} //check if userid exists in state
                        {...rest}
                        className="thumbnail"
                        width="10%"
                      />
                    </Labeled>
                  );
                }
              }}
            </FormDataConsumer>
            <ImageInput
              source="userImage"
              label="Profile Photo"
              accept="image/png, image/jpeg"
              maxSize={300000}
              onChange={onchange}
              placeholder="Drop a photo to update, or click to select it.logo should not greater than 50kb"
            >
              <ImageField source="src" width="10%" title="title" />
            </ImageInput>
          </SimpleForm>
        </>
      ) : (
        <>
          <SimpleForm
            toolbar={
              <PostEditReject disable={true} status={status} props={props} />
            }
          >
            <SelectInput
              defaultValue={state.orgName}
              placeholder="Select organization"
              source="orgSelection"
              label="Select organization"
              fullWidth={true}
              required={true}
              onChange={handleInput}
              formClassName={classes.dropdown}
              choices={organizationlist}
              // validate={locationRequired}
            />

            <Typography variant="h6" gutterBottom style={{ marginLeft: "8%" }}>
              Organization Form
            </Typography>

            <TextInput
              InputProps={{ inputProps: { style: { color: "black" } } }}
              disabled
              defaultValue={state.orgName}
              autoFocus
              fullWidth={true}
              source="organizationname"
              label="Organization Name"
              formClassName={classes.inline1}
            />

            <TextInput
              InputProps={{ inputProps: { style: { color: "black" } } }}
              disabled
              defaultValue={state.orgDomain}
              fullWidth={true}
              source="organizationdomain"
              label="Organization Domain"
              formClassName={classes.inline2}
            />

            <TextInput
              InputProps={{ inputProps: { style: { color: "black" } } }}
              disabled
              defaultValue={state.orgWebsite}
              autoFocus
              fullWidth={true}
              source="organizationwebsite"
              label="Organization Website"
              formClassName={classes.inline1}
            />

            <TextInput
              InputProps={{ inputProps: { style: { color: "black" } } }}
              defaultValue={state.city}
              disabled
              source="location"
              fullWidth={true}
              label="Location"
              formClassName={classes.inline2}
            />
            <TextInput
              InputProps={{ inputProps: { style: { color: "black" } } }}
              disabled
              defaultValue={state.orgAddress}
              autoFocus
              fullWidth={true}
              source="organizationaddress"
              label="Organization Address"
              formClassName={classes.inline1}
            />

            <TextInput
              InputProps={{ inputProps: { style: { color: "black" } } }}
              disabled
              defaultValue={state.employeeid}
              autoFocus
              source="employeeid"
              label="Employee Id"
              fullWidth={true}
              formClassName={classes.inline2}
              required={true}
            />

            <TextInput
              InputProps={{ inputProps: { style: { color: "black" } } }}
              defaultValue={state.name}
              source="name"
              autoFocus
              fullWidth={true}
              label="Name"
              formClassName={classes.inline1}
            />

            <TextInput
              InputProps={{ inputProps: { style: { color: "black" } } }}
              defaultValue={state.email}
              autoFocus
              disabled
              source="email"
              label="Employee Email"
              fullWidth={true}
              formClassName={classes.inline2}
              required={true}
            />

            <TextInput
              InputProps={{ inputProps: { style: { color: "black" } } }}
              defaultValue={state.designation}
              source="designation"
              label="Designation"
              fullWidth={true}
              formClassName={classes.inline1}
              required={true}
            />

            <TextInput
              InputProps={{ inputProps: { style: { color: "black" } } }}
              defaultValue={state.contactnumber}
              source="contactnumber"
              label="Contact Number"
              fullWidth={true}
              formClassName={classes.inline2}
              required={true}
            />
            <FormDataConsumer
              source="oldImage"
              style={{ height: "7%", width: "100%" }}
            >
              {({ formData, dispatch, ...rest }) => {
                if (!formData.organizationimg) {
                  return (
                    <Labeled label="Profile photo">
                      <img
                        src={`${USER_PROFILE_IMAGE}/${state.userId}/profile.png`} //check if userid exists in state
                        {...rest}
                        className="thumbnail"
                        width="10%"
                      />
                    </Labeled>
                  );
                }
              }}
            </FormDataConsumer>
            <ImageInput
              style={{ height: "7%", width: "30%" }}
              source="userImage"
              label="Profile Photo"
              accept="image/png, image/jpeg"
              maxSize={300000}
              onChange={onchange}
              placeholder="Drop a photo to update, or click to select it.logo should not greater than 50kb"
            >
              <ImageField source="src" width="10%" title="title" />
            </ImageInput>
          </SimpleForm>
        </>
      );
    } else if (status === "T") {
      return isXSmall && isSmall ? (
        <>
          {!editApproved ? (
            <>
              <SimpleForm
                toolbar={
                  <PostApprove disable={true} status={status} props={props} />
                }
              >
                <SelectInput
                  defaultValue={state.orgName}
                  placeholder="Select organization"
                  source="orgSelection"
                  label="Select organization"
                  fullWidth={true}
                  required={true}
                  onChange={handleInput}
                  choices={organizationlist}
                />

                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ marginLeft: "8%" }}
                >
                  Organization Form
                </Typography>

                <TextInput
                  InputProps={{ inputProps: { style: { color: "#6c757d" } } }}
                  disabled
                  defaultValue={state.orgName}
                  autoFocus
                  fullWidth={true}
                  source="organizationname"
                  label="Organization Name"
                />

                <TextInput
                  InputProps={{ inputProps: { style: { color: "#6c757d" } } }}
                  disabled
                  defaultValue={state.orgDomain}
                  fullWidth={true}
                  source="organizationdomain"
                  label="Organization Domain"
                />

                <TextInput
                  InputProps={{ inputProps: { style: { color: "#6c757d" } } }}
                  disabled
                  defaultValue={state.orgWebsite}
                  autoFocus
                  fullWidth={true}
                  source="organizationwebsite"
                  label="Organization Website"
                />

                <TextInput
                  InputProps={{ inputProps: { style: { color: "#6c757d" } } }}
                  disabled
                  defaultValue={state.orgAddress}
                  autoFocus
                  fullWidth={true}
                  source="organizationaddress"
                  label="Organization Address"
                />

                <TextInput
                  InputProps={{ inputProps: { style: { color: "black" } } }}
                  defaultValue={state.city}
                  disabled
                  source="location"
                  fullWidth={true}
                  label="Location"
                />

                <TextInput
                  InputProps={{ inputProps: { style: { color: "#6c757d" } } }}
                  disabled
                  defaultValue={state.employeeid}
                  autoFocus
                  source="employeeid"
                  label="Employee Id"
                  fullWidth={true}
                  required={true}
                />

                <TextInput
                  InputProps={{ inputProps: { style: { color: "#6c757d" } } }}
                  disabled
                  defaultValue={state.email}
                  autoFocus
                  source="email"
                  label="Employee Email"
                  fullWidth={true}
                  required={true}
                />

                <TextInput
                  autoFocus
                  source="name"
                  fullWidth={true}
                  label="Name"
                  required={true}
                  defaultValue={state.name}
                  disabled
                />

                <TextInput
                  defaultValue={state.designation}
                  source="designation"
                  label="Designation"
                  fullWidth={true}
                  required={true}
                  disabled
                />

                <TextInput
                  defaultValue={state.contactnumber}
                  source="contactnumber"
                  label="Contact Number"
                  fullWidth={true}
                  required={true}
                  disabled
                />
                <FormDataConsumer
                  source="oldImage"
                  style={{ height: "7%", width: "100%" }}
                >
                  {({ formData, dispatch, ...rest }) => {
                    if (!formData.organizationimg) {
                      return (
                        <Labeled label="Profile photo">
                          <img
                            src={`${USER_PROFILE_IMAGE}/${state.userId}/profile.png`} //check if userid exists in state
                            {...rest}
                            className="thumbnail"
                            width="10%"
                          />
                        </Labeled>
                      );
                    }
                  }}
                </FormDataConsumer>
              </SimpleForm>
            </>
          ) : (
            <>
              <SimpleForm
                toolbar={<PostEditApprove status={status} props={props} />}
              >
                <SelectInput
                  placeholder="Select organization"
                  source="orgSelection"
                  label="Select organization"
                  fullWidth={true}
                  defaultValue={state.orgName}
                  required={true}
                  onChange={handleInput}
                  choices={organizationlist}
                />

                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ marginLeft: "8%" }}
                >
                  Organization Form
                </Typography>

                <TextInput
                  InputProps={{ inputProps: { style: { color: "#6c757d" } } }}
                  disabled
                  defaultValue={state.orgName}
                  autoFocus
                  fullWidth={true}
                  source="organizationname"
                  label="Organization Name"
                />

                <TextInput
                  InputProps={{ inputProps: { style: { color: "#6c757d" } } }}
                  disabled
                  defaultValue={state.orgDomain}
                  fullWidth={true}
                  source="organizationdomain"
                  label="Organization Domain"
                />

                <TextInput
                  InputProps={{ inputProps: { style: { color: "#6c757d" } } }}
                  disabled
                  defaultValue={state.orgWebsite}
                  autoFocus
                  fullWidth={true}
                  source="organizationwebsite"
                  label="Organization Website"
                />

                <TextInput
                  InputProps={{ inputProps: { style: { color: "#6c757d" } } }}
                  defaultValue={state.city}
                  disabled
                  source="location"
                  fullWidth={true}
                  label="Location"
                />
                <TextInput
                  InputProps={{ inputProps: { style: { color: "#6c757d" } } }}
                  disabled
                  defaultValue={state.orgAddress}
                  autoFocus
                  fullWidth={true}
                  source="organizationaddress"
                  label="Organization Address"
                />

                <TextInput
                  InputProps={{ inputProps: { style: { color: "#6c757d" } } }}
                  disabled
                  defaultValue={state.employeeid}
                  autoFocus
                  source="employeeid"
                  label="Employee Id"
                  fullWidth={true}
                  required={true}
                />

                <TextInput
                  InputProps={{ inputProps: { style: { color: "black" } } }}
                  defaultValue={state.name}
                  source="name"
                  autoFocus
                  fullWidth={true}
                  label="Name"
                />

                <TextInput
                  InputProps={{ inputProps: { style: { color: "#6c757d" } } }}
                  defaultValue={state.email}
                  disabled
                  source="email"
                  label="Employee Email"
                  fullWidth={true}
                  required={true}
                />

                <TextInput
                  InputProps={{ inputProps: { style: { color: "black" } } }}
                  defaultValue={state.designation}
                  source="designation"
                  label="Designation"
                  fullWidth={true}
                  required={true}
                />

                <TextInput
                  InputProps={{ inputProps: { style: { color: "black" } } }}
                  defaultValue={state.contactnumber}
                  source="contactnumber"
                  label="Contact Number"
                  fullWidth={true}
                  required={true}
                />
                <FormDataConsumer
                  source="oldImage"
                  style={{ height: "7%", width: "30%" }}
                >
                  {({ formData, dispatch, ...rest }) => {
                    if (!formData.organizationimg) {
                      return (
                        <Labeled label="Profile photo">
                          <img
                            src={`${USER_PROFILE_IMAGE}/${state.userId}/profile.png`} //check if userid exists in state
                            {...rest}
                            className="thumbnail"
                            width="10%"
                          />
                        </Labeled>
                      );
                    }
                  }}
                </FormDataConsumer>

                <ImageInput
                  source="userImage"
                  label="Profile Photo"
                  accept="image/png, image/jpeg"
                  maxSize={300000}
                  onChange={onchange}
                  placeholder="Drop a photo to update, or click to select it.logo should not greater than 50kb"
                >
                  <ImageField source="src" width="10%" title="title" />
                </ImageInput>
              </SimpleForm>
            </>
          )}
        </>
      ) : (
        <>
          {!editApproved ? (
            <>
              <SimpleForm
                toolbar={
                  <PostApprove disable={true} status={status} props={props} />
                }
              >
                <SelectInput
                  placeholder="Select organization"
                  source="orgSelection"
                  label="Select organization"
                  fullWidth={true}
                  required={true}
                  onChange={handleInput}
                  defaultValue={state.orgName}
                  formClassName={classes.dropdown}
                  choices={organizationlist}
                />

                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ marginLeft: "8%" }}
                >
                  Organization Form
                </Typography>

                <TextField
                  InputProps={{ inputProps: { style: { color: "black" } } }}
                  disabled
                  value={state.orgName}
                  autoFocus
                  fullWidth={true}
                  source="organizationname"
                  label="Organization Name"
                  formClassName={classes.inlineP1}
                />

                <TextField
                  InputProps={{ inputProps: { style: { color: "black" } } }}
                  disabled
                  value={state.orgDomain}
                  fullWidth={true}
                  source="organizationdomain"
                  label="Organization Domain"
                  formClassName={classes.inlineP2}
                />

                <TextField
                  InputProps={{ inputProps: { style: { color: "black" } } }}
                  disabled
                  value={state.orgWebsite}
                  autoFocus
                  fullWidth={true}
                  source="organizationwebsite"
                  label="Organization Website"
                  formClassName={classes.inlineP1}
                />

                <TextField
                  InputProps={{ inputProps: { style: { color: "black" } } }}
                  disabled
                  value={state.orgAddress}
                  autoFocus
                  fullWidth={true}
                  source="organizationaddress"
                  label="Organization Address"
                  formClassName={classes.inlineP2}
                />

                <TextField
                  InputProps={{ inputProps: { style: { color: "black" } } }}
                  value={state.city}
                  disabled
                  source="location"
                  fullWidth={true}
                  label="Location"
                  formClassName={classes.inlineP1}
                />

                <TextField
                  InputProps={{ inputProps: { style: { color: "black" } } }}
                  disabled
                  value={state.employeeid}
                  autoFocus
                  source="employeeid"
                  label="Employee Id"
                  fullWidth={true}
                  formClassName={classes.inlineP2}
                  required={true}
                />

                <TextField
                  InputProps={{ inputProps: { style: { color: "black" } } }}
                  value={state.name}
                  disabled
                  source="name"
                  fullWidth={true}
                  label="Name"
                  formClassName={classes.inlineP1}
                />

                <TextField
                  InputProps={{ inputProps: { style: { color: "black" } } }}
                  disabled
                  value={state.email}
                  autoFocus
                  source="email"
                  label="Employee Email"
                  fullWidth={true}
                  formClassName={classes.inlineP2}
                  required={true}
                />

                <TextField
                  InputProps={{ inputProps: { style: { color: "black" } } }}
                  disabled
                  value={state.designation}
                  autoFocus
                  source="designation"
                  label="Designation"
                  fullWidth={true}
                  formClassName={classes.inlineP1}
                  required={true}
                />

                <TextField
                  InputProps={{ inputProps: { style: { color: "black" } } }}
                  disabled
                  value={state.contactnumber}
                  autoFocus
                  source="contactnumber"
                  label="Contact Number"
                  fullWidth={true}
                  formClassName={classes.inlineP2}
                  required={true}
                />

                <FormDataConsumer
                  source="oldImage"
                  style={{ height: "7%", width: "100%" }}
                >
                  {({ formData, dispatch, ...rest }) => {
                    if (!formData.organizationimg) {
                      return (
                        <Labeled label="Profile photo">
                          <img
                            src={`${USER_PROFILE_IMAGE}/${state.userId}/profile.png`} //check if userid exists in state
                            {...rest}
                            className="thumbnail"
                            width="10%"
                          />
                        </Labeled>
                      );
                    }
                  }}
                </FormDataConsumer>
              </SimpleForm>
            </>
          ) : (
            <>
              <SimpleForm
                toolbar={<PostEditApprove status={status} props={props} />}
              >
                <SelectInput
                  placeholder="Select organization"
                  source="orgSelection"
                  label="Select organization"
                  fullWidth={true}
                  required={true}
                  defaultValue={state.orgName}
                  onChange={handleInput}
                  formClassName={classes.dropdown}
                  choices={organizationlist}
                />

                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ marginLeft: "8%" }}
                >
                  Organization Form
                </Typography>

                <TextInput
                  InputProps={{ inputProps: { style: { color: "#6c757d" } } }}
                  disabled
                  defaultValue={state.orgName}
                  fullWidth={true}
                  source="organizationname"
                  label="Organization Name"
                  formClassName={classes.inline1}
                />

                <TextInput
                  InputProps={{ inputProps: { style: { color: "#6c757d" } } }}
                  disabled
                  defaultValue={state.orgDomain}
                  fullWidth={true}
                  source="organizationdomain"
                  label="Organization Domain"
                  formClassName={classes.inline2}
                />

                <TextInput
                  InputProps={{ inputProps: { style: { color: "#6c757d" } } }}
                  disabled
                  defaultValue={state.orgWebsite}
                  fullWidth={true}
                  source="organizationwebsite"
                  label="Organization Website"
                  formClassName={classes.inline1}
                />

                <TextInput
                  InputProps={{ inputProps: { style: { color: "#6c757d" } } }}
                  defaultValue={state.city}
                  disabled
                  source="location"
                  fullWidth={true}
                  label="Location"
                  formClassName={classes.inline2}
                />
                <TextInput
                  InputProps={{ inputProps: { style: { color: "#6c757d" } } }}
                  disabled
                  defaultValue={state.orgAddress}
                  fullWidth={true}
                  source="organizationaddress"
                  label="Organization Address"
                  formClassName={classes.inline1}
                />

                <TextInput
                  InputProps={{ inputProps: { style: { color: "#6c757d" } } }}
                  disabled
                  defaultValue={state.employeeid}
                  source="employeeid"
                  label="Employee Id"
                  fullWidth={true}
                  formClassName={classes.inline2}
                  required={true}
                />

                <TextInput
                  InputProps={{ inputProps: { style: { color: "black" } } }}
                  defaultValue={state.name}
                  source="name"
                  autoFocus
                  fullWidth={true}
                  label="Name"
                  formClassName={classes.inline1}
                />

                <TextInput
                  InputProps={{ inputProps: { style: { color: "#6c757d" } } }}
                  defaultValue={state.email}
                  disabled
                  source="email"
                  label="Employee Email"
                  fullWidth={true}
                  formClassName={classes.inline2}
                  required={true}
                />

                <TextInput
                  InputProps={{ inputProps: { style: { color: "black" } } }}
                  defaultValue={state.designation}
                  source="designation"
                  label="Designation"
                  fullWidth={true}
                  formClassName={classes.inline1}
                  required={true}
                />

                <TextInput
                  InputProps={{ inputProps: { style: { color: "black" } } }}
                  defaultValue={state.contactnumber}
                  source="contactnumber"
                  label="Contact Number"
                  fullWidth={true}
                  formClassName={classes.inline2}
                  required={true}
                />
                <FormDataConsumer
                  source="oldImage"
                  style={{ height: "7%", width: "30%" }}
                >
                  {({ formData, dispatch, ...rest }) => {
                    if (!formData.organizationimg) {
                      return (
                        <Labeled label="Profile photo">
                          <img
                            src={`${USER_PROFILE_IMAGE}/${state.userId}/profile.png`} //check if userid exists in state
                            {...rest}
                            className="thumbnail"
                            width="10%"
                          />
                        </Labeled>
                      );
                    }
                  }}
                </FormDataConsumer>
                <ImageInput
                  source="userImage"
                  label="Profile Photo"
                  accept="image/png, image/jpeg"
                  maxSize={300000}
                  onChange={onchange}
                  placeholder="Drop a photo to update, or click to select it.logo should not greater than 50kb"
                >
                  <ImageField source="src" width="10%" title="title" />
                </ImageInput>
              </SimpleForm>
            </>
          )}
        </>
      );
    } else if (status === null) {
      return isXSmall && isSmall ? (
        <>
          {!editApproved ? (
            <>
              <SimpleForm
                toolbar={
                  <ApproveNull disable={true} status={status} props={props} />
                }
              >
                <SelectInput
                  defaultValue={state.orgName}
                  placeholder="Select organization"
                  source="orgSelection"
                  label="Select organization"
                  fullWidth={true}
                  required={true}
                  onChange={handleInput}
                  choices={organizationlist}
                />

                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ marginLeft: "8%" }}
                >
                  Organization Form
                </Typography>

                <TextInput
                  InputProps={{ inputProps: { style: { color: "#6c757d" } } }}
                  disabled
                  defaultValue={state.orgName}
                  autoFocus
                  fullWidth={true}
                  source="organizationname"
                  label="Organization Name"
                />

                <TextInput
                  InputProps={{ inputProps: { style: { color: "#6c757d" } } }}
                  disabled
                  defaultValue={state.orgDomain}
                  fullWidth={true}
                  source="organizationdomain"
                  label="Organization Domain"
                />

                <TextInput
                  InputProps={{ inputProps: { style: { color: "#6c757d" } } }}
                  disabled
                  defaultValue={state.orgWebsite}
                  autoFocus
                  fullWidth={true}
                  source="organizationwebsite"
                  label="Organization Website"
                />

                <TextInput
                  InputProps={{ inputProps: { style: { color: "#6c757d" } } }}
                  disabled
                  defaultValue={state.orgAddress}
                  autoFocus
                  fullWidth={true}
                  source="organizationaddress"
                  label="Organization Address"
                />

                <TextInput
                  InputProps={{ inputProps: { style: { color: "black" } } }}
                  defaultValue={state.city}
                  disabled
                  source="location"
                  fullWidth={true}
                  label="Location"
                />

                <TextInput
                  InputProps={{ inputProps: { style: { color: "#6c757d" } } }}
                  disabled
                  defaultValue={state.employeeid}
                  autoFocus
                  source="employeeid"
                  label="Employee Id"
                  fullWidth={true}
                  required={true}
                />

                <TextInput
                  InputProps={{ inputProps: { style: { color: "#6c757d" } } }}
                  disabled
                  defaultValue={state.email}
                  autoFocus
                  source="email"
                  label="Employee Email"
                  fullWidth={true}
                  required={true}
                />

                <TextInput
                  autoFocus
                  source="name"
                  fullWidth={true}
                  label="Name"
                  required={true}
                  defaultValue={state.name}
                  disabled
                />

                <TextInput
                  defaultValue={state.designation}
                  source="designation"
                  label="Designation"
                  fullWidth={true}
                  required={true}
                  disabled
                />

                <TextInput
                  defaultValue={state.contactnumber}
                  source="contactnumber"
                  label="Contact Number"
                  fullWidth={true}
                  required={true}
                  disabled
                />
              </SimpleForm>
            </>
          ) : (
            <>
              <SimpleForm
                toolbar={<PostEditApprove status={status} props={props} />}
              >
                <SelectInput
                  placeholder="Select organization"
                  source="orgSelection"
                  label="Select organization"
                  fullWidth={true}
                  defaultValue={state.orgName}
                  required={true}
                  onChange={handleInput}
                  choices={organizationlist}
                />

                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ marginLeft: "8%" }}
                >
                  Organization Form
                </Typography>

                <TextInput
                  InputProps={{ inputProps: { style: { color: "#6c757d" } } }}
                  disabled
                  defaultValue={state.orgName}
                  autoFocus
                  fullWidth={true}
                  source="organizationname"
                  label="Organization Name"
                />

                <TextInput
                  InputProps={{ inputProps: { style: { color: "#6c757d" } } }}
                  disabled
                  defaultValue={state.orgDomain}
                  fullWidth={true}
                  source="organizationdomain"
                  label="Organization Domain"
                />

                <TextInput
                  InputProps={{ inputProps: { style: { color: "#6c757d" } } }}
                  disabled
                  defaultValue={state.orgWebsite}
                  autoFocus
                  fullWidth={true}
                  source="organizationwebsite"
                  label="Organization Website"
                />

                <TextInput
                  InputProps={{ inputProps: { style: { color: "#6c757d" } } }}
                  defaultValue={state.city}
                  disabled
                  source="location"
                  fullWidth={true}
                  label="Location"
                />
                <TextInput
                  InputProps={{ inputProps: { style: { color: "#6c757d" } } }}
                  disabled
                  defaultValue={state.orgAddress}
                  autoFocus
                  fullWidth={true}
                  source="organizationaddress"
                  label="Organization Address"
                />

                <TextInput
                  InputProps={{ inputProps: { style: { color: "#6c757d" } } }}
                  defaultValue={state.employeeid}
                  autoFocus
                  source="employeeid"
                  label="Employee Id"
                  fullWidth={true}
                  required={true}
                />

                <TextInput
                  InputProps={{ inputProps: { style: { color: "black" } } }}
                  defaultValue={state.name}
                  source="name"
                  autoFocus
                  fullWidth={true}
                  label="Name"
                  required={true}
                />

                <TextInput
                  InputProps={{ inputProps: { style: { color: "#6c757d" } } }}
                  defaultValue={state.email}
                  disabled
                  source="email"
                  label="Employee Email"
                  fullWidth={true}
                  required={true}
                />

                <TextInput
                  InputProps={{ inputProps: { style: { color: "black" } } }}
                  defaultValue={state.designation}
                  source="designation"
                  label="Designation"
                  fullWidth={true}
                  required={true}
                />

                <TextInput
                  InputProps={{ inputProps: { style: { color: "black" } } }}
                  defaultValue={state.contactnumber}
                  source="contactnumber"
                  label="Contact Number"
                  fullWidth={true}
                  required={true}
                />
              </SimpleForm>
            </>
          )}
        </>
      ) : (
        <>
          {!editApproved ? (
            <>
              <SimpleForm
                toolbar={
                  <ApproveNull disable={true} status={status} props={props} />
                }
              >
                <SelectInput
                  placeholder="Select organization"
                  source="orgSelection"
                  label="Select organization"
                  fullWidth={true}
                  required={true}
                  defaultValue={state.orgName}
                  onChange={handleInput}
                  formClassName={classes.dropdown}
                  choices={organizationlist}
                />

                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ marginLeft: "8%" }}
                >
                  Organization Form
                </Typography>

                <TextInput
                  InputProps={{ inputProps: { style: { color: "#6c757d" } } }}
                  disabled
                  defaultValue={state.orgName}
                  autoFocus
                  fullWidth={true}
                  source="organizationname"
                  label="Organization Name"
                  formClassName={classes.inline1}
                />

                <TextInput
                  InputProps={{ inputProps: { style: { color: "#6c757d" } } }}
                  disabled
                  defaultValue={state.orgDomain}
                  fullWidth={true}
                  source="organizationdomain"
                  label="Organization Domain"
                  formClassName={classes.inline2}
                />

                <TextInput
                  InputProps={{ inputProps: { style: { color: "#6c757d" } } }}
                  disabled
                  defaultValue={state.orgWebsite}
                  autoFocus
                  fullWidth={true}
                  source="organizationwebsite"
                  label="Organization Website"
                  formClassName={classes.inline1}
                />

                <TextInput
                  InputProps={{ inputProps: { style: { color: "#6c757d" } } }}
                  disabled
                  defaultValue={state.orgAddress}
                  autoFocus
                  fullWidth={true}
                  source="organizationaddress"
                  label="Organization Address"
                  formClassName={classes.inline2}
                />

                <TextInput
                  InputProps={{ inputProps: { style: { color: "#6c757d" } } }}
                  defaultValue={state.city}
                  disabled
                  source="location"
                  fullWidth={true}
                  label="Location"
                  formClassName={classes.inline1}
                />

                <TextInput
                  InputProps={{ inputProps: { style: { color: "#6c757d" } } }}
                  disabled
                  defaultValue={state.employeeid}
                  autoFocus
                  source="employeeid"
                  label="Employee Id"
                  fullWidth={true}
                  formClassName={classes.inline2}
                  required={true}
                />

                <TextInput
                  InputProps={{ inputProps: { style: { color: "#6c757d" } } }}
                  disabled
                  defaultValue={state.email}
                  autoFocus
                  source="email"
                  label="Employee Email"
                  fullWidth={true}
                  formClassName={classes.inline1}
                  required={true}
                />

                <TextInput
                  autoFocus
                  source="name"
                  fullWidth={true}
                  label="Name"
                  formClassName={classes.inline2}
                  required={true}
                  defaultValue={state.name}
                  disabled
                />

                <TextInput
                  defaultValue={state.designation}
                  source="designation"
                  label="Designation"
                  fullWidth={true}
                  formClassName={classes.inline1}
                  required={true}
                  disabled
                />

                <TextInput
                  defaultValue={state.contactnumber}
                  source="contactnumber"
                  label="Contact Number"
                  fullWidth={true}
                  formClassName={classes.inline2}
                  required={true}
                  disabled
                />
              </SimpleForm>
            </>
          ) : (
            <>
              <SimpleForm
                toolbar={<PostEditApprove status={status} props={props} />}
              >
                <SelectInput
                  placeholder="Select organization"
                  source="orgSelection"
                  label="Select organization"
                  fullWidth={true}
                  required={true}
                  defaultValue={state.orgName}
                  onChange={handleInput}
                  formClassName={classes.dropdown}
                  choices={organizationlist}
                />

                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ marginLeft: "8%" }}
                >
                  Organization Form
                </Typography>

                <TextInput
                  InputProps={{ inputProps: { style: { color: "#6c757d" } } }}
                  disabled
                  defaultValue={state.orgName}
                  fullWidth={true}
                  source="organizationname"
                  label="Organization Name"
                  formClassName={classes.inline1}
                />

                <TextInput
                  InputProps={{ inputProps: { style: { color: "#6c757d" } } }}
                  disabled
                  defaultValue={state.orgDomain}
                  fullWidth={true}
                  source="organizationdomain"
                  label="Organization Domain"
                  formClassName={classes.inline2}
                />

                <TextInput
                  InputProps={{ inputProps: { style: { color: "#6c757d" } } }}
                  disabled
                  defaultValue={state.orgWebsite}
                  fullWidth={true}
                  source="organizationwebsite"
                  label="Organization Website"
                  formClassName={classes.inline1}
                />

                <TextInput
                  InputProps={{ inputProps: { style: { color: "#6c757d" } } }}
                  defaultValue={state.city}
                  disabled
                  source="location"
                  fullWidth={true}
                  label="Location"
                  formClassName={classes.inline2}
                />
                <TextInput
                  InputProps={{ inputProps: { style: { color: "#6c757d" } } }}
                  disabled
                  defaultValue={state.orgAddress}
                  fullWidth={true}
                  source="organizationaddress"
                  label="Organization Address"
                  formClassName={classes.inline1}
                />

                <TextInput
                  InputProps={{ inputProps: { style: { color: "#6c757d" } } }}
                  defaultValue={state.employeeid}
                  source="employeeid"
                  label="Employee Id"
                  fullWidth={true}
                  formClassName={classes.inline2}
                  required={true}
                  autoFocus
                />

                <TextInput
                  InputProps={{ inputProps: { style: { color: "black" } } }}
                  defaultValue={state.name}
                  source="name"
                  fullWidth={true}
                  label="Name"
                  required={true}
                  formClassName={classes.inline1}
                />

                <TextInput
                  InputProps={{ inputProps: { style: { color: "#6c757d" } } }}
                  defaultValue={state.email}
                  disabled
                  source="email"
                  label="Employee Email"
                  fullWidth={true}
                  formClassName={classes.inline2}
                  required={true}
                />

                <TextInput
                  InputProps={{ inputProps: { style: { color: "black" } } }}
                  defaultValue={state.designation}
                  source="designation"
                  label="Designation"
                  fullWidth={true}
                  formClassName={classes.inline1}
                  required={true}
                />

                <TextInput
                  InputProps={{ inputProps: { style: { color: "black" } } }}
                  defaultValue={state.contactnumber}
                  source="contactnumber"
                  label="Contact Number"
                  fullWidth={true}
                  formClassName={classes.inline2}
                  required={true}
                />
              </SimpleForm>
            </>
          )}
        </>
      );
    }
  };

  const PostEditPending = (props) => (
    <>
      <Toolbar style={{ marginLeft: "8%", width: "65%" }}>
        <SaveButton
          disabled
          fullWidth={true}
          label="Wait for Approval"
          {...props}
        />
      </Toolbar>

      <div
        style={{
          display: "inline",
          width: "100%",
          marginLeft: "10%",
          color: "#ffc107",
        }}
      >
        *Your request is not approved yet. Wait for approval.
      </div>
    </>
  );

  const PostEditReject = (props) => (
    <>
      <Toolbar style={{ marginLeft: "8%", width: "65%" }}>
        <SaveButton
          onSave={onsubmit}
          fullWidth={true}
          label="Send Request"
          {...props}
        />
      </Toolbar>
      <div
        style={{
          display: "inline",
          width: "100%",
          marginLeft: "10%",
          color: "red",
        }}
      >
        *Your approval request is rejected.Send request again with correct data.
      </div>
    </>
  );

  const PostApprove = (props) => (
    <>
      <Toolbar style={{ marginLeft: "8%", width: "65%" }}>
        <SaveButton
          onSave={onAcceptsubmit}
          fullWidth={true}
          label="Edit & Send Request"
          {...props}
        />
      </Toolbar>
      <div
        style={{
          display: "inline",
          width: "100%",
          marginLeft: "10%",
          color: "green",
        }}
      >
        Your request for above details is approved. Check{" "}
        <Link to="/corporate-card-view">View Card </Link>section{" "}
      </div>
    </>
  );

  const ApproveNull = (props) => (
    <>
      <Toolbar style={{ marginLeft: "8%", width: "65%" }}>
        <SaveButton
          onSave={onAcceptsubmit}
          fullWidth={true}
          label="Edit & Send Request"
          {...props}
        />
      </Toolbar>
    </>
  );

  const PostEditApprove = (props) => (
    <>
      <Toolbar
        alwaysEnableSaveButton={false}
        style={{ marginLeft: "8%", width: "65%" }}
      >
        <SaveButton
          onSave={onsubmit}
          fullWidth={true}
          label="Send Request"
          {...props}
        />
      </Toolbar>
    </>
  );

  const registerNewOrg = () => {
    refresh(true);
    history.push({
      pathname: "/card-design",
      search: "?query=new_org",
      state: ["new_organization"],
    });
  };

  return (
    <div>{state && RequestStatus(state.is_approved, state.orgName, flag)}</div>
  );
};

export default EditCardDetails;
