import React, { useState } from "react";
import {
  TextInput,
  SimpleForm,
  required,
  useNotify,
  useRedirect,
  Toolbar,
  SaveButton,
  SelectInput,
  NumberInput,
  ImageInput,
  ImageField,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import { NODE_API } from "../../../../config";

export const styles = {
  inline1: {
    display: "inline-block",
    color: "black",
    width: "30%",
    marginLeft: "8%",
  },
  inline2: {
    display: "inline-block",
    color: "black",
    marginLeft: "5%",
    width: "30%",
  },
  dropdown: { marginLeft: "8%", width: "65%" },
  location: { display: "inline-block", marginLeft: 20, width: "40%" },
  form: {
    marginLeft: "50%",
    display: "flex",
    justifyContent: "center",
  },
};

const useStyles = makeStyles(styles);

const nameValidation = (value) => {
  var checkNull = value.replaceAll(/\s/g, "");
  if (!value || checkNull == "") {
    return "should not be null";
  }
  return undefined;
};

const validatename = [required(), nameValidation];
const validatecontact = [required()];
const locationRequired = [required()];

const CardDetailsForm = (props) => {
  const redirect = useRedirect();
  const notify = useNotify();
  const classes = useStyles();
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [state, setState] = useState({ enableSaveBtn: true });
  var locationCity = [];

  async function addBusinessCardInitialRequest(logoData, data, orgId) {
    let user = JSON.parse(sessionStorage.getItem("user"));
    let token, reqData;

    if (user && user.validator) {
      token = user.validator;
    } else {
      token = {};
    }

    reqData = {
      organizationID: orgId,
      name: data.name,
      designation: data.designation,
      employeeid: data.employeeid,
      contactnumber: data.contactnumber,
      email: data.email,
      locationID: state.locationId,
    };

    if (logoData) {
      reqData.userImage = logoData;
    }

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      data: JSON.stringify(reqData),
    };

    return axios(`${NODE_API}/user/card/business`, requestOptions)
      .then(async (res) => {
        if (res.data.status && res.data.status === "Database error") {
          notify(`Error Sending Request`, { type: "warning" });
          redirect("/");
        } else if (res.status && res.status === 200) {
          notify(`Your Request has been sent successfully`, {
            type: "success",
          });
          redirect("/");
          setState((state) => ({
            ...state,

            enableSaveBtn: false,
          }));
        } else {
          notify(`Something went wrong try again later`, { type: "warning" });
          redirect("/");
        }

        return res;
      })
      .catch((e) => {
        notify(`Something went wrong try again later`, { type: "warning" });
        redirect("/");
      });
  }

  const onsubmit = async (data) => {
    const { organizationID } = props;

    if (data && data.userImage) {
      var file = data.userImage;

      const reader = new FileReader();
      reader.readAsDataURL(file.rawFile);
      reader.onload = async () =>
        await addBusinessCardInitialRequest(
          reader.result,
          data,
          organizationID
        );
      reader.onerror = () =>
        notify(`Something went wrong`, { type: "warning" });
    } else {
      await addBusinessCardInitialRequest(null, data, organizationID);
    }
  };

  const PostEditToolbar = (props, enableSaveBtn) => (
    <>
      {" "}
      {enableSaveBtn ? (
        <Toolbar style={{ marginLeft: "8%", width: "30%" }}>
          <SaveButton
            onSave={onsubmit}
            fullWidth={true}
            label="Send Aproval Request"
            {...props}
          />
        </Toolbar>
      ) : (
        <Toolbar style={{ marginLeft: "8%", width: "30%" }}>
          <SaveButton
            disabled
            onSave={onsubmit}
            fullWidth={true}
            label="Wait For Approval"
            {...props}
          />
        </Toolbar>
      )}
    </>
  );

  const hasLocation = (props) => {
    let orgName, orgWebsite, orgDomain;

    const { locationSelected, organizationList, corporateMail } = props;

    const { enableSaveBtn, locationAddress } = state;

    const handleInput = (event) => {
      let locationAddress, locationId;
      const { organizationList } = props;

      organizationList.map((val) => {
        if (event.target.value === `${val.city}  ${val.locationName}`) {
          locationAddress = val.address;
          locationId = val.loc_id;
        }
      });

      setState((state) => ({
        ...state,

        locationAddress: locationAddress,

        locationId: locationId,
      }));
    };

    organizationList.map((val) => {
      locationCity.push({
        value: val.city,
        label: val.city,
        name: `${val.city}  ${val.locationName}`,
        id: `${val.city}  ${val.locationName}`,
        address: val.address,
      });
      orgName = val.name;
      orgDomain = val.domain;
      orgWebsite = val.website;
    });

    return isXSmall && isSmall ? (
      <>
        <SimpleForm
          toolbar={
            <PostEditToolbar props={props} enableSaveBtn={enableSaveBtn} />
          }
        >
          <Typography variant="h6" gutterBottom style={{ marginLeft: "8%" }}>
            Organization Form
          </Typography>

          <TextInput
            InputProps={{ inputProps: { style: { color: "#6c757d" } } }}
            disabled
            defaultValue={orgName}
            fullWidth={true}
            source="organizationname"
            label="Organization Name"
          />

          <TextInput
            InputProps={{ inputProps: { style: { color: "#6c757d" } } }}
            disabled
            defaultValue={orgDomain}
            fullWidth={true}
            source="organizationdomain"
            label="Organization Domain"
          />

          <TextInput
            InputProps={{ inputProps: { style: { color: "#6c757d" } } }}
            disabled
            defaultValue={orgWebsite}
            fullWidth={true}
            source="organizationwebsite"
            label="Organization Website"
          />

          <SelectInput
            autoFocus
            placeholder="Select Location "
            source="city"
            label="Select Location"
            fullWidth={true}
            onChange={handleInput}
            required={true}
            choices={locationCity}
            validate={locationRequired}
          />

          <TextInput
            InputProps={{ inputProps: { style: { color: "#6c757d" } } }}
            disabled
            defaultValue={locationAddress}
            fullWidth={true}
            source="organizationaddress"
            label="Organization Address"
          />

          <TextInput
            InputProps={{ inputProps: { style: { color: "#6c757d" } } }}
            disabled
            defaultValue={corporateMail}
            source="email"
            label="Corporate Mail Id"
            fullWidth={true}
          />

          <TextInput
            source="employeeid"
            label="Employee Id"
            fullWidth={true}
            validate={validatename}
          />

          <TextInput
            source="name"
            label="Employee Name (as you want to see on card)"
            fullWidth={true}
            validate={validatename}
          />

          <TextInput
            source="designation"
            label="Designation"
            fullWidth={true}
            validate={validatename}
            required={true}
          />
          <NumberInput
            source="contactnumber"
            label="Contact Number"
            fullWidth={true}
            required={true}
            validate={validatecontact}
          />

          <ImageInput
            source="userImage"
            label="Profile Photo"
            accept="image/png, image/jpeg"
            maxSize={300000}
            onChange={onchange}
            placeholder="Drop a photo to update, or click to select it.logo should not greater than 50kb"
          >
            <ImageField source="src" width="10%" title="title" />
          </ImageInput>
        </SimpleForm>
      </>
    ) : (
      <>
        <SimpleForm
          toolbar={
            <PostEditToolbar props={props} enableSaveBtn={enableSaveBtn} />
          }
        >
          <Typography variant="h6" gutterBottom style={{ marginLeft: "8%" }}>
            Organization Form
          </Typography>

          <TextInput
            InputProps={{ inputProps: { style: { color: "#6c757d" } } }}
            disabled
            defaultValue={orgName}
            fullWidth={true}
            source="organizationname"
            label="Organization Name"
            formClassName={classes.inline1}
          />

          <TextInput
            InputProps={{ inputProps: { style: { color: "#6c757d" } } }}
            disabled
            defaultValue={orgDomain}
            fullWidth={true}
            source="organizationdomain"
            label="Organization Domain"
            formClassName={classes.inline2}
          />

          <TextInput
            InputProps={{ inputProps: { style: { color: "#6c757d" } } }}
            disabled
            defaultValue={orgWebsite}
            fullWidth={true}
            source="organizationwebsite"
            label="Organization Website"
            formClassName={classes.inline1}
          />

          <SelectInput
            placeholder="Select Location "
            source="city"
            autoFocus
            label="Select Location"
            fullWidth={true}
            required={true}
            onChange={handleInput}
            formClassName={classes.inline2}
            choices={locationCity}
            validate={locationRequired}
          />

          <TextInput
            InputProps={{ inputProps: { style: { color: "#6c757d" } } }}
            disabled
            defaultValue={locationAddress}
            fullWidth={true}
            source="organizationaddress"
            label="Organization Address"
            formClassName={classes.inline1}
          />

          <TextInput
            InputProps={{ inputProps: { style: { color: "#6c757d" } } }}
            disabled
            defaultValue={corporateMail}
            source="email"
            label="Corporate Mail Id"
            fullWidth={true}
            formClassName={classes.inline2}
          />

          <TextInput
            source="employeeid"
            label="Employee Id"
            fullWidth={true}
            formClassName={classes.inline1}
            validate={validatename}
          />

          <TextInput
            source="name"
            label="Employee Name"
            fullWidth={true}
            formClassName={classes.inline2}
            validate={validatename}
          />

          <TextInput
            source="designation"
            label="Designation"
            fullWidth={true}
            formClassName={classes.inline1}
            validate={validatename}
            required={true}
          />
          <NumberInput
            source="contactnumber"
            label="Contact Number"
            fullWidth={true}
            formClassName={classes.inline2}
            required={true}
            validate={validatecontact}
          />
          <ImageInput
            source="userImage"
            label="Profile Photo"
            accept="image/png, image/jpeg"
            maxSize={300000}
            onChange={onchange}
            placeholder="Drop a photo to update, or click to select it.logo should not greater than 50kb"
          >
            <ImageField source="src" width="10%" title="title" />
          </ImageInput>
        </SimpleForm>
      </>
    );
  };

  return hasLocation(props);
};

export default CardDetailsForm;
