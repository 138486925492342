import React, { useEffect, useState } from "react";
import {
  useDataProvider,
  ImageInput,
  ImageField,
  TextInput,
  SimpleForm,
  regex,
  required,
  useNotify,
  useRedirect,
  useVersion,
  Labeled,
  FormDataConsumer,
  SelectInput,
  Create
}
  from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery, TextField } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import { NODE_API } from '../../../../config';
import { IMAGE_API } from '../../../../config';



export const styles = {
  inline1: { display: "inline-block", color: 'black', width: '40%', },
  inline2: { display: "inline-block", color: 'black', marginLeft: '5%', width: '40%' },
  inline3: { display: "inline-block", color: 'black', width: '40%' },


  name: { display: "inline-block", color: 'black', width: '30%', backgroundColor: '#ececec', margin: '1%', padding: '5px' },
  referCode: { color: 'black', width: '30%', padding: '5px' },
  domain: { display: "inline-block", color: 'black', width: '30%', backgroundColor: '#ececec', margin: '1%', padding: '5px' },
  website: { display: "inline-block", color: 'black', width: '30%', backgroundColor: '#ececec', margin: '1%', padding: '5px' },
  imageDiv: {width: '50%'},
  form: {
    borderColor:'pink',
    borderWidth:'5px',
    width:'70%',
    marginLeft: '50%',
    display: 'flex',
    justifyContent: 'center'
  }
};

const useStyles = makeStyles(styles);

const nameValidation = (value) => {
  var checkNull = value.replaceAll(/\s/g, '')
  if (!value || checkNull == "") {
    return 'should not be null';
  }
  return undefined;
};
const validatename = [required(), nameValidation]
const validatewebsite = [regex(/^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/, 'Must be a valid website'), required()];
const validatedomain = [regex(/^((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}\.(xn--)?([a-z0-9\-]{1,61}|[a-z0-9-]{1,30}\.[a-z]{2,})$/, 'Must be a valid domain'), required()];

var userorganizationidd = ""
var orgName = "", orgDomain = "", orgWebsite = "", referCode = "";

const EditOrganization = () => {
  const redirect = useRedirect();
  const notify = useNotify();
  const classes = useStyles();
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const [state, setState] = useState({});
  const version = useVersion();

  async function editOrganization(logoData, organizationName, organizationDetails) {
    let user = JSON.parse(sessionStorage.getItem('user'));
    let token, reqData;;

    if (user && user.validator) {
      token = user.validator
    } else {
      token = {}
    }

    if (logoData) {
      reqData = {
        name: organizationDetails.organizationname,
        domain: organizationDetails.organizationdomain,
        website: organizationDetails.organizationwebsite,
        refer_code: organizationDetails.refercode,
        myFile: logoData
      }
    } else {
      reqData = {
        name: organizationDetails.organizationname,
        domain: organizationDetails.organizationdomain,
        website: organizationDetails.organizationwebsite,
        refer_code: organizationDetails.refercode,
      }
    }

    const requestOptions = {
      method: 'PUT',
      headers:
      {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      data: JSON.stringify(reqData)

    };

    return axios(`${NODE_API}/organization/update?name=${organizationName}`, requestOptions)

      .then(async res => {
        if (res.data.status && res.data.status === "Database error") {
          notify(`Organization already register`, { type: 'warning' })
          redirect('/')
        }
        else if (res.status && res.status === 200) {
          notify(`Organization details successfully Updated`, { type: 'success' })
          redirect('/')
        }
        else {
          notify(`Something went wrong try again later`, { type: 'warning' })
          redirect('/')
        }

        return res;
      }).catch(e => {
        notify(`Something went wrong try again later`, { type: 'warning' })
      });
  }


  const getOrgDetails = async () => {

    var details = []

    let user = JSON.parse(sessionStorage.getItem('user'));
    let token;

    if (user && user.validator) {
      token = user.validator
    } else {
      token = {}
    }

    const requestOptions = {
      method: 'GET',
      headers:
      {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    };

    await axios(`${NODE_API}/organization/details/${userorganizationidd}`, requestOptions)

      .then(res => {

        details = res.data

        details.company.map(function (element) {
          orgName = element.name
          orgDomain = element.domain
          orgWebsite = element.website
          referCode = element.refer_code

        })

        setState(state => ({

          ...state,

          orgName: orgName,
          orgDomain: orgDomain,
          orgWebsite: orgWebsite,
          referCode: referCode

        }));

        return res;

      }).catch(e => {

        console.log('error : ' + e)

        notify(`Something went wrong try again later`, { type: 'warning' })

      });
    ;



  }

  const userorganization = async () => {
    let user = JSON.parse(sessionStorage.getItem('user'));
    let token;

    if (user && user.validator) {
      token = user.validator
    } else {
      token = {}
    }

    const requestOptions = {
      method: 'GET',
      headers:
      {
        'Content-Type': 'application/json',
        'Authorization': token
      }

    };

    // requestOptions
    await axios(`${NODE_API}/organization/list/userorganization`, requestOptions)

      .then(res => {

        var organizationlist = []

        res.data.data.map(val => {
          if (val.type_id == 2) {
            organizationlist.push({ id: val.id, value: val.name, label: val.name, name: val.name })

            userorganizationidd = val.id
          }

        })
        if (organizationlist) {
          setState(state => ({

            ...state,
            id: userorganizationidd,
            organizationlist: organizationlist

          }))
        }

      }).catch(e => {
        notify(`Something went wrong try again later`, { type: 'warning' })

      });


  }

  const handleInputList = async (event) => {
    var idtarget = event.target.value
    userorganizationidd = idtarget
    setState(state => ({
      ...state,
      id: idtarget
    })
    )

    getOrgDetails()

  }

  useEffect(() => {
    userorganization().then(res => {
      getOrgDetails()
    })

  }, [version])



  const onsubmit = async (data) => {
    var organizationName = (data.organizationname).replace(/\s/g, "");

    if (data && data.organizationimg) {
      var file = data.organizationimg
      const reader = new FileReader();
      reader.readAsDataURL(file.rawFile);
      reader.onload = async () => await editOrganization(reader.result, organizationName, data)
      reader.onerror = () => notify(`Something went wrong`, { type: 'warning' })
    } else {

      await editOrganization(null, organizationName, data)

    }

  };

  return isXSmall ? (
    <>
      {state.organizationlist && state.organizationlist.length > 1 ? (

        <SimpleForm toolbar={false}>
          <SelectInput
            placeholder='Select organization'
            source="orgSelection"
            label="Select organization"
            fullWidth={true}
            required={true}
            defaultValue={userorganizationidd}
            onChange={handleInputList}
            style={{ width: "500px" }}
            choices={state.organizationlist}
          />
        </SimpleForm>
      ) : <></>}

      <SimpleForm save={onsubmit}>
        <Typography variant="h6" gutterBottom>
          Update Organization Details
        </Typography>
        <TextInput
          disabled
          defaultValue={state.referCode}
          autoFocus
          source="refercode"
          label="organization Refer Code"
          validate={validatename}
        />

        <TextInput
          disabled
          defaultValue={state.orgName}
          autoFocus
          source="organizationname"
          label="organization name"
          validate={validatename}
        />

        <TextInput
          defaultValue={state.orgDomain}
          source="organizationdomain"
          label="organization domain"
          validate={validatedomain} />

        <TextInput
          defaultValue={state.orgWebsite}
          source="organizationwebsite"
          label="organization website"
          validate={validatewebsite}
        />

        <FormDataConsumer>
          {({ formData, dispatch, ...rest }) => {

            if (!formData.organizationimg) {
              return (
                <Labeled label="Old image">

                  <img src={`${IMAGE_API}/${state.orgName}/${state.orgName}.png`} {...rest} className="thumbnail" width="10%" />

                </Labeled>
              );
            }
          }}
        </FormDataConsumer>


        <ImageInput
          source='organizationimg'
          label="organization logo"                                  
          accept="image/png, image/jpeg"
          maxSize="50000"
          onChange={onchange}
          placeholder="Drop a logo to update, or click to select it.logo should not greater than 50kb"
        >
          <ImageField source="thumbnail" width="10%" title="title" />
        </ImageInput>
        <p style={{ color: "green", fontSize: "70%" }}>* Logo should not be greater than 50kb</p>

      </SimpleForm>
    </>
  ) :
    isSmall ? (
      <>

        {state.organizationlist && state.organizationlist.length > 1 ? (

          <SimpleForm toolbar={false}>
            <SelectInput
              placeholder='Select organization'
              source="orgSelection"
              label="Select organization"
              fullWidth={true}
              required={true}
              defaultValue={userorganizationidd}
              onChange={handleInputList}
              style={{ width: "500px" }}
              // formClassName={classes.dropdown}
              choices={state.organizationlist}
            // validate={locationRequired}
            />
          </SimpleForm>
        ) : <></>}

        <SimpleForm save={onsubmit}>
          <Typography variant="h6" gutterBottom>
            Update Organization Details
          </Typography>
          <TextInput
            disabled
            defaultValue={state.referCode}
            autoFocus
            source="refercode"
            label="organization Refer Code"
            validate={validatename}
          />

          <TextInput
            disabled
            defaultValue={state.orgName}
            autoFocus
            source="organizationname"
            label="organization name"
            validate={validatename}
          />

          <TextInput
            defaultValue={state.orgDomain}
            source="organizationdomain"
            label="organization domain"
            validate={validatedomain} />

          <TextInput
            defaultValue={state.orgWebsite}
            source="organizationwebsite"
            label="organization website"
            validate={validatewebsite}
          />

          <FormDataConsumer>
            {({ formData, dispatch, ...rest }) => {

              if (!formData.organizationimg) {
                return (
                  <Labeled label="Old image">

                    <img src={`${IMAGE_API}/${state.orgName}/${state.orgName}.png`} {...rest} className="thumbnail" width="10%" />

                  </Labeled>
                );
              }
            }}
          </FormDataConsumer>


          <ImageInput
            source='organizationimg'
            label="organization logo" 
            accept="image/png, image/jpeg"
            maxSize="50000"
            onChange={onchange}
            placeholder="Drop a logo to update, or click to select it.logo should not greater than 50kb"
          >
            <ImageField source="thumbnail" width="10%" title="title" />
          </ImageInput>
          <p style={{ color: "green", fontSize: "70%" }}>* Logo should not be greater than 50kb</p>

        </SimpleForm>
      </>

    ) :
      (
        <>
          {state.organizationlist && state.organizationlist.length > 1 ? (
            <>
              <SimpleForm toolbar={false}>
                <SelectInput
                  placeholder='Select organization'
                  source="orgSelection"
                  label="Select organization"
                  fullWidth={true}
                  required={true}
                  defaultValue={userorganizationidd}
                  onChange={handleInputList}
                  formClassName={classes.referCode}
                  choices={state.organizationlist}
                />
              </SimpleForm>
            </>
          ) : <></>}

          <SimpleForm save={onsubmit} formclassname={classes.form} >

            <Typography variant="h6" gutterBottom >
              Update Organization Details
            </Typography>

            <TextInput
              autoFocus
              disabled
              defaultValue={state.orgName}
              fullWidth={true}
              source="organizationname"
              label="organization name"
              formClassName={classes.inline1}
            />

            <TextInput
              InputProps={{ inputProps: { style: { color: 'black' } } }}
              defaultValue={state.orgDomain}
              fullWidth={true}
              source="organizationdomain"
              label="organization domain"
              formClassName={classes.inline2}
            />
            <TextInput
              InputProps={{ inputProps: { style: { color: 'black' } } }}

              defaultValue={state.orgWebsite}
              fullWidth={true}
              source="organizationwebsite"
              label="organization website"
              formClassName={classes.inline3}
            />


            <FormDataConsumer style={{ height: "7%", width: "10%" }}>
              {({ formData, dispatch, ...rest }) => {

                if (!formData.organizationimg) {
                  return (
                    <Labeled label="Old image">

                      <img src={`${IMAGE_API}/${state.orgName}/${state.orgName}.png`} {...rest} className="thumbnail" width="10%" />

                    </Labeled>
                  );
                }
              }}
            </FormDataConsumer>


            <ImageInput
              source='organizationimg'
              label="organization logo" 
              accept="image/png, image/jpeg"
              maxSize="50000"
              onChange={onchange}
              formClassName={classes.imageDiv}
              placeholder="Drop a logo to update, or click to select it.logo should not greater than 50kb"
            >
              <ImageField source="thumbnail" width="10%" title="title" />

            </ImageInput>


            <p style={{ color: "#eed202", fontSize: "70%" }}>* Logo should not be greater than 50kb</p>

          </SimpleForm>
        </>



      );
};



export default EditOrganization;